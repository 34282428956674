import React, { useState, useEffect, useCallback } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Toast } from 'antd-mobile'

import Camera from '../../camera'
import PreviewDlg from '../../previewDlg'
import { commitFileByCert } from '@/service/file/index'
import {getEditStudentImgServerUrl, getFileInfo} from '../../util/urlCreator'
import { getCertificatePhotoId, reCertificatePhoto } from '@/service/student/index'
import Dialog from '@/components/dialog'
import WithPrettyCheckbox from '../../layout/WithPrettyCheckbox'

import DelSVG from '@/static/images/del.svg'
import ZoomSVG from '@/static/images/zoom.svg'
import './center.css'


/**
 * 证件照拍照(寸照)，因为按照PC端进入电子照页面就刷新证件照，否则后端不会将此数据加入缓存，无法判断是否用到头像，签字会报错，所以单独加载渲染
 * 由父组件查询并传递
 * 整体有四层 1. 灰色背景  2. 触发开启相机的透明遮罩 3. 背景取景框  4. 根据拍照次数和类型决定的背景图  4. 相机图片
 */
function CertCamera(props: Picture.UploadFile & {showPretty: boolean, certCountChange: (num: number) => void}): JSX.Element {
  // 对于制证照有独立接口提供，不需要父组件持有其文件id itemImgChange
  const { defaultImg, text, type, steps, submitUrl, studentId, title, showPretty, certCountChange, } = props
  const [img, setImg] = useState(defaultImg)
  const [fileId, setFileId] = useState('')
  // 是否缩放
  const [isZoomed, setIsZoomed] = useState(false)

  let dlgPre = null

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  useEffect(() => {
    profileRefresh()

  }, [fileId])

  const profileRefresh = () => {
    getCertificatePhotoId({ studentId }).then(res => {
      if (res.data) {
        setFileId(res.data)
        loadImg(res.data)
      }
    })
  }

  // 加载图片
  const loadImg = (fileId?: string) => {
    let src:string
    if(!fileId) {
      src = getEditStudentImgServerUrl(type);
    } else {
      src = getFileInfo(fileId)
    }
    setImg(src)
  }

  // 删除图片按钮事件
  const deleteImage = () => {
    reCertificatePhoto({ studentId }).then(() => {
      setFileId('')
      setImg(defaultImg)
    })
  }

  // 查看图片按钮
  const imgZoom = () => {
    setIsZoomed(true)
  }

  // 构造背景
  const cameraList = () => {
    const _zoom = {
      isZoomed: isZoomed,
      onZoomChange: handleZoomChange,
      overlayBgColorStart: 'rgba(0, 0, 0, 0)',
      overlayBgColorEnd: 'rgba(0, 0, 0, 0.5)',
    }

    return img !== null ?
      <ControlledZoom {..._zoom}><img className="center-img" src={img} key={img} alt={text} /></ControlledZoom> : null
  }

  // 选择拍照还是签字的图片, 不是拍照就是签字, 当被填充了真实文件时，不可见
  const renderFlag = () => {
    return fileId ? null : <div className="camera-flag" />
  }

  // 提交图片，接口和数据格式不统一, 身份证明正反面、原驾驶证、暂住证、体检表 调算法
  const commitImg = (imgList) => {
    const formData = new FormData()
    const blob = imgList[0]
    const ps = showPretty ? 1 : 0
    formData.append('file', blob, new Date().getTime() + (blob.type.includes('jp') ? '.jpeg' : '.png'));
    formData.append('studentId', studentId);
    formData.append('photoService', ps.toString());
    commitFileByCert(submitUrl, formData).then(res => {
      console.log(res)
      Toast.success(`上传${title}成功`, 2, () => {
        loadImg(res.data.fileId)
        setFileId(res.data.fileId)
        // setCertCount(res.data.count)
        certCountChange(res.data.count)
        dlgPre && dlgPre.close()
      })
    })

  }

  const renderCamera = () => {
    if (fileId) {
      return null
    }
    const cameraProps = {
      className: '',
      changeCallback: function (filestr: File) {
        if (!filestr) {
          Toast.fail('图片文件不能为空！')
          return
        }
        // setImg(filestr)
        dlgPre = openDlg({
          type,
          file: filestr,
          // n次拍摄时显示的标题, 子组件依赖此判断步骤次数
          steps: steps,
          fileIndex: 0,  // 图片索引，多次拍摄时，确定更新的位置, 0 开始
          submitUrl: submitUrl,  // 上传地址
          // submitBefore?: () => {},
          // submitAfter?: () => {},
          onSave: (imgList?: File[]) => {
            console.log('save image', imgList)
            commitImg(imgList)
          }    // 拍摄完成
        })
      }
    }
    return <Camera {...cameraProps} />
  }

  const openDlg = (params) => {
    return Dialog(<PreviewDlg {...params} />)
  }

  // 构造处理区域, 按钮组等
  const renderOperArea = () => {
    const operBtns = [
      <a key="zoom" className="oper-btn oper-zoom" onClick={() => imgZoom()}><img className="oper-img" src={ZoomSVG} alt="放大" /><span className="oper-txt">放大</span></a>,
      <a key="del" className="oper-btn oper-del" onClick={() => deleteImage()}><img className="oper-img" src={DelSVG} alt="删除" /><span className="oper-txt">删除</span></a>
    ]
    if (fileId) {
      return operBtns
    } else {
      return null
    }
  }

  return (
    <>
      <div className="imgload-bg">
        <div className="flex-center">
          <div className="block">
            {cameraList()}
            {renderFlag()}
          </div>
          {text ? <span className="bg-txt">{text}</span> : null}
        </div>
        {renderCamera()}
      </div>
      <div className="oper-area">
        {renderOperArea()}
      </div>
    </>
  )
}

export default WithPrettyCheckbox(CertCamera)
