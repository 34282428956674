/**
 * 图片处理
 */

const IMAGE_WIDTH = 352;
const IMAGE_HEIGHT = 454;

// eslint-disable-next-line no-undef
if (!HTMLCanvasElement.prototype.toBlob) {
  // eslint-disable-next-line no-undef
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value: function (callback, type, quality) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const canvas = this;
      // eslint-disable-next-line no-undef
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        const binStr = atob(canvas.toDataURL(type, quality).split(",")[1]);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback(
          // eslint-disable-next-line no-undef
          new Blob([arr], {
            type: type || "image/png",
          })
        );
      });
    },
  });
}

function syncReaderFile(file) {
  return new Promise(function (resolve) {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (eve) {
      resolve(eve.target.result)
    }
  })
}

function syncImgLoad(img) {
  return new Promise(function (resolve) {
    const image = document.createElement('img')
    image.src = img
    image.onload = function () {
      resolve(image)
    }
  })
}

/**
 * 图片压缩
 * @param file {File||Blob} 通常是input file 获取的文件
 * @param defaultReturn {string}  扩展重写
 * @returns {Promise<string|*>}
 */
export async function compressImg(file, defaultReturn = 'base64') {
  let image = file
  const size = file.size
  const maxSize = 1024 * 1024 * 1
  if (size < maxSize) {
    if (defaultReturn === 'blob') {
      return file
    }
  }
  // eslint-disable-next-line no-undef
  if (image instanceof File || image instanceof Blob) {
    const srcImg = await syncReaderFile(image)
    image = await syncImgLoad(srcImg)
  }
  const canvas = document.createElement("canvas");
  let width = image.width || IMAGE_WIDTH
  let height = image.height || IMAGE_HEIGHT

  canvas.width = width;
  canvas.height = height;
  // canvas.attributes("width", image.width || IMAGE_WIDTH).attr("height", image.height || IMAGE_HEIGHT);
  console.log(width, height)
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, width, height);
  ctx.drawImage(image, 0, 0, width, height);
  let quality = 0.85
  if(size > maxSize) {
    quality = 0.5
  }
  const mUrl = canvas.toDataURL("image/jpeg", quality)

  let res
  if (defaultReturn === 'blob') {
    res = base64PicToBlob(mUrl)
  } else {
    res = mUrl
  }

  return res
}

export function compressImgToBlob(file, defaultReturn = 'blob') {
  return compressImg(file, defaultReturn)
}

// base64 图片转blob
export function base64PicToBlob(base64Pic, type = 'image/jpeg') {
  const base64 = base64Pic.split(',')[1];
  const str = window.atob(base64);
  let n = str.length;
  const arr = new Uint8Array(n);
  while (n--) {
    arr[n] = str.charCodeAt(n);
  }

  return new Blob([arr], {type});
}
