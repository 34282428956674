import React, { useState, useEffect } from 'react'

import Camera from '../camera'
import type { btnProps } from '../../../components/FlexBtn';
import LiBtns from '../../../components/FlexBtn';



const renderBtnText = (pretxt: string, txt: string): string => {
  if (txt) {
    return `${pretxt}并${txt}`
  }
  return `${pretxt}`
}

/**
 * 分为父组件触发和自更新
 * 父组件唤醒拍摄后获取到文件，触发此组件初始化
 * @param props
 * @returns
 */
function Preview(props: Picture.previewProps): JSX.Element {
  // titles 描述了要操作的步骤，文本被合成到按钮上显示
  const { file, fileIndex, steps } = props
  const { onSave } = props
  const [imgList, setImgList] = useState([file])    // 父组件触发拍摄后，传递过来的照片文件, 记录n次拍摄
  const [current, setCurrent] = useState(fileIndex) // 当前拍摄的步骤
  const [currUrl, setCurrUrl] = useState('')  // 当前展示的图片url

  function renderCameraBtn({ name, callback, className }) {
    return (
      <div className={className}>
        <Camera className="" changeCallback={callback} />
        <span>{name}</span>
      </div>
    )
  }

  useEffect(() => {
    if (imgList[current]) {
      if (currUrl) {
        console.log('revoke url')
        URL.revokeObjectURL(currUrl)
      }
      setCurrUrl(getUrl())
    }
  }, [imgList])

  // 重新拍摄事件，文件嵌入current作为索引的图片数组中
  const cameraChange = (img: File) => {
    const tempArr = [].concat(imgList)
    tempArr[current] = img
    setImgList([...tempArr])
  }

  // 步骤驱动
  const stepClickHandler = (img: File) => {
    const step = current + 1
    if (step === steps.length) { // 完成了，该提交
      onSave && onSave(imgList)
    }

    // 设置步进器
    setCurrent(step)

    const tempArr = [].concat(imgList)
    tempArr[step] = img

    // 设置文件列表
    setImgList([...tempArr])
  }

  // 只获取图片传给父组件，提交由父组件完成
  const saveCamera = function () {
    // TODO: 算法端接口调用 imgList
    onSave && onSave(imgList)
  }

  // 两个按钮，左为重新拍摄，右的文本根据步骤改变
  const renderBtns = () => {
    const btns: btnProps[] = []

    const btn = {
      name: '重新拍摄',
      className: 'btn-last btn-camera',
      callback: cameraChange,
    }

    btns.push(renderCameraBtn(btn))

    // 当还存在拍摄步骤时，不会保存，按钮实际执行的还是拍摄事件
    const lastIndex = steps.length - 1;
    if (current + 1 === lastIndex) { // 最后一步时是纯按钮，不被高阶组装
      btns.push({
        name: '保存',
        className: '',
        onClick: saveCamera
      });
    } else {
      const txt = renderBtnText('保存', steps[current + 1])
      btn.callback = stepClickHandler
      btn.name = txt
      btn.className = 'btn-next'
      btns.push(renderCameraBtn(btn))
    }

    const props = {
      arr: btns
    }

    return <LiBtns {...props} />
  }

  const getUrl = () => {
    console.log('set current img', current)
    return URL.createObjectURL(imgList[current])
  }

  const imgProps = {
    className: 'pre-image',
    src: currUrl,
    alt: steps[current]
  }

  return (
    <>
      <div className="pre-title">{steps[current]}</div>
      <div className="preview">
        <img {...imgProps} />
      </div>
      <div className="btns ipx">
        {renderBtns()}
      </div>
    </>
  )
}

export default Preview
