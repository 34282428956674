import { createForm } from 'rc-form';
import { useState, useEffect } from 'react';
import LiCreateForm from '../../components/createForm/index'
import { fromTableName,fromTableType} from '../identityInfo/index.data'
import { fromTableMobile,fromTableContactRegion } from '../registrationInfo/index.data'
import { getCountry } from '@/service/erp/system'
import { getTemplateInfo } from '@/service/erp/system'
import util from '../../utils/util';

const BasicForm = (props)=>{
    // 是否填暂住证
    const [forbid,setforbid] = useState(true)
    // 国籍
    const [country, setCountry] = useState(null)
    // 证件类型
    const [templateErtificateList, setTemplateErtificateList] = useState(null)
    // 所学车型
    const [template, setTemplate] = useState(null)
    // 省市地区
    const [regionBySource, setRegionBySource] = useState(null)
    // 省市地区
    const [localitySource, setLocalitySource] = useState(null)
    // 获取所有模板信息

    // form是否完成
    const [formSuccess, setFormSuccess] = useState(false);

    useEffect(() => {
      getTemplateInfo().then((res) => {
        if (res.code == 'success') {
          setTemplate(res.data.templateModelList)
          setTemplateErtificateList(res.data.templateErtificateList)
        }
      }).catch(()=>{
        return null
      })

      // 处理国家数据
      getCountry().then((res) => {
        if (res.code == 'success') {
          setCountry(res.data)
        }
      }).catch(()=>{
        return null
      })

      //处理学生来源
      util.getSource('D').then(res => setRegionBySource(res))

    }, [])

    useEffect(() => {
      const info = {...props.info}
      const typeId = info.typeId+''
      const carId = info.carId+''
      delete info.fileList
      delete info.orgRegion
      util.getSource(info.source).then(res =>setLocalitySource(res))
      const typeIdArr = ['2','14493']
      const carIdArr = ['11','12','13','14','15']
      if (info.source == 'B') {
        if (typeIdArr.indexOf(typeId) === -1) {
          setforbid(false)
        } else {
          if (carIdArr.indexOf(carId) === -1) {
            setforbid(false)
          } else {
            setforbid(true)
          }
        }
      } else {
        setforbid(true)
      }
    },[])

    useEffect(()=>{
      // 如果完成就进行setFieldsValue
      if(formSuccess){
        const info = {...props.info}
        props.form.setFieldsValue(info)
      }
    },[formSuccess])

    const renderDetailsForm = () => {
      const startRender = country && templateErtificateList && regionBySource && template && localitySource
      if(startRender) {
        if(!formSuccess){
          setFormSuccess(true);
        }
        return (
          <>
            <LiCreateForm fromTable={fromTableName(true)} Props={props} />
            <LiCreateForm fromTable={fromTableType(country, templateErtificateList, localitySource,true)} Props={props} />
            <div className='interval' />
            <LiCreateForm fromTable={fromTableMobile(template,true,true)} Props={props} />
            <LiCreateForm fromTable={fromTableContactRegion(regionBySource,forbid,true)} Props={props} />
          </>
        )
      }
      return null
    }

  return (
    <div>{renderDetailsForm()}</div>
  )
}

export default createForm()(BasicForm);
