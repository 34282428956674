import React, {useEffect, useMemo, useState} from "react";
import {Checkbox} from "antd-mobile";
import {checkConsumePhotoByIdnumAndCarId, getCurrentCountByOrgId} from "../../../service/org";

function WithPrettyCheckbox(Component) {
  return (props) => {
    // 是否使用过制证照美化， 由接口结果确定
    const [isUseService, setIsUseService] = useState(false)
    // 是否使用美化, 如果
    const [showPretty, setShowPretty] = useState(false)
    // 剩余制证照次数
    const [certCount, setCertCount] = useState(0)

    const {idnum, carId, studentId} = props

    useEffect(() => {
      checkConsumePhotoByIdnumAndCarId({ // 处理制证照美化是否使用
        idnum,
        studentId,
        carId,
      }).then(res => {
        setIsUseService(res.data)
        if (res.data) { //
          setShowPretty(true)
        }
      });
    }, [certCount])

    useEffect(() => { // 查制证照剩余次数， orgId 由小程序入口进入时url传递
      getCurrentCountByOrgId(localStorage.getItem('orgId')).then(res => {
        setCertCount(res.data)
      })
    }, [])

    const certCountChange = (num: number) => {
      setCertCount(num)
    }

    const _props = useMemo(() => {
      return {
        showPretty,
        certCountChange,
        ...props
      }
    }, [showPretty])

    return (
      <>
        <Component {..._props} />
        <div className="oper-extra">
          <Checkbox className="checkbox-pretty" checked={showPretty} disabled={isUseService} onChange={(e) => setShowPretty(e.target.checked)} />
          <span className="oper-extra-txt">{`证件照专业服务（照片抠图、人像美化、打 印）剩余次数：${certCount}次`}</span>
        </div>
      </>
    )
  }
}

export default  WithPrettyCheckbox
