import { useEffect, useState } from 'react'
import { Toast, Modal } from 'antd-mobile';
import RenderBtns from '../../../components/FlexBtn/index';
import { createForm } from 'rc-form';
import LiCreateForm from '../../../components/createForm/index'
import { fromTableMobile, fromTableContactRegion } from './index.data'
import { firstStep } from '@/service/open'
import util from '../../../utils/util';
import styles from './index.module.css';
import qs from 'qs'

const alert = Modal.alert;

const Index = (props) => {

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { unionid = '', city = '' } = query || {};

  // 获取填写修改数据
  useEffect(() => {
    const mobileShow = sessionStorage.getItem('mobileShow')
    const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
    if (getregistrationInfo) {
      const param = {
        carId: getregistrationInfo.carId,
        contactAddress: getregistrationInfo.contactAddress,
        contactRegion: getregistrationInfo.contactRegion,
        email: getregistrationInfo.email,
        mobile: getregistrationInfo.mobile,
        phone: getregistrationInfo.phone,
        residenceNumber: getregistrationInfo.residenceNumber,
      }
      props.form.setFieldsValue(param)

      if (getregistrationInfo.learningType) {
        props.form.setFieldsValue({
          learningType: getregistrationInfo.learningType
        })
      }
      // 判断是否渲染手机验证码，防止报错
      if (mobileShow !== '2') {
        if (getregistrationInfo.studentId) {
          props.form.setFieldsValue({
            mcode: getregistrationInfo.mcode
          })
        } else {
          props.form.setFieldsValue({
            mcode: ''
          })
        }
      }
    }
  }, [])

  // 监听手机号码的改变
  useEffect(() => {
    // 判断是否渲染手机验证码，防止报错
    const mobileShow = sessionStorage.getItem('mobileShow')
    if (mobileShow !== '2') {
      const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
      if (getregistrationInfo && getregistrationInfo.studentId) {
        const mobile = props.form.getFieldValue('mobile')
        if (getregistrationInfo.mobile != mobile) {
          setCodeForbid(false)
          props.form.setFieldsValue({
            mcode: ''
          })
        } else {
          props.form.setFieldsValue({
            mcode: '已验证'
          })
          setCodeForbid(true)
        }
      }
    }
  }, [props.form.getFieldValue('mobile')])

  // 初始值
  const templateList = [{
    carCode: 'A2',
    carName: 'A2:牵引车',
    dictId: '7',
    endAge: 60,
    id: '377590821302420',
    startAge: 22,
    templateId: '260461583749268',
    type: 2,
  }]
  const [template, setTemplate] = useState(templateList)
  const [regionBySource, setRegionBySource] = useState([{ label: '省', value: '1', children: [{ label: '市', value: '2', children: [{ label: '区', value: '3' }] }] }])
  // 是否必填暂住证编号
  const [forbid, setforbid] = useState(true)
  const [codeForbid, setCodeForbid] = useState(false)

  // 监听学习类型改变
  useEffect(() => {
    const templateListType = []
    const learningType = props.form.getFieldValue('learningType') + ''
    const templateModelList = props.template.templateModelList || []
    templateModelList.forEach(element => {
      if ((element.type - 1) == Number(learningType)) {
        templateListType.push(element)
      }
    });
    setTemplate(templateListType)
  }, [props.form.getFieldValue('learningType') + ''])

  // 监听学习类型改变 判断暂住证是否必填
  useEffect(() => {
    const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
    const localSourc = getregistrationInfo.source + ''
    const typeId = getregistrationInfo.typeId + ''
    const typeIdArr = ['2', '14493']
    if (localSourc === 'B') {
      if (typeIdArr.indexOf(typeId) === -1) {
        setforbid(false)
        props.form.setFieldsValue({
          residenceNumber: getregistrationInfo.residenceNumber || ''
        })
      } else {
        setforbid(true)
        props.form.setFieldsValue({
          residenceNumber: ''
        })
      }
    } else {
      setforbid(true)
      props.form.setFieldsValue({
        residenceNumber: ''
      })
    }
  }, [props.form.getFieldValue('carId') + ''])

  // 监听学员来源改变
  useEffect(() => {
    const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
    const localSourc = getregistrationInfo.source + ''
    const typeId = getregistrationInfo.typeId + ''
    const typeIdArr = ['2', '14493']
    let isForbid = true;
    if (localSourc == 'B') {
      props.form.setFieldsValue({
        'residenceNumber': getregistrationInfo.residenceNumber || ''
      })
      if (typeIdArr.indexOf(typeId) === -1) {
        isForbid = false;
        props.form.setFieldsValue({
          residenceNumber: getregistrationInfo.residenceNumber || ''
        })
      } else {
        props.form.setFieldsValue({
          'residenceNumber': ''
        })
      }
    } else {
      props.form.setFieldsValue({
        'residenceNumber': ''
      })
    }
    setforbid(isForbid)
    util.getSource(isForbid ? 'E' : 'D', city).then((res) => {
      setRegionBySource(res)
      const contactRegion = props.form.getFieldValue('contactRegion')
      if (contactRegion) {
        const zipCode = util.findZipCode(res, contactRegion[2])
        props.form.setFieldsValue({
          'zipCode': zipCode
        })
      }
    }).catch(() => {
      return null
    })
  }, [])

  // 提交前验证
  const submitBefore = (info) => {
    info.readCard = 1; // 通过移动端报名的学员，设置为未读卡  20210514
    let flag = true
    const age = util.getAgeByBirthday(info.birthday);
    const email = info.email
    const carId = info.carId
    const residenceNumber = info.residenceNumber
    const idnum = info.idnum
    const typeId = info.typeId
    // 输入邮箱后将进行邮箱验证
    if (email && !util.REGEX_EMAIL.test(email)) {
      return Toast.fail('请输入正确的电子邮箱', 1)
    }

    // 当需要填写暂住证编号且证件类型 为身份证的时候
    if (residenceNumber && typeId == '2' && idnum != residenceNumber) {
      return Toast.fail('暂住证和身份证不一致', 1)
    }

    // C1、C2、F车型 , 70以上的
    if (age > 70 && age < 101) {
      const carType_70 = ['11', '12', '18']
      if (carType_70.indexOf(carId) != -1) {
        flag = false
        alert('提示', `当前申领年龄${age},大于70周岁，请核实是否已通过记忆力、判断力和反应力能力测试，是否继续？`, [
          { text: '取消', onPress: () => { return } },
          { text: '继续', onPress: () => { onSubmitInfo(info) } },
        ])
      }
    }
    // A1、A2、A3、B1、B2车型, 年龄为56~60岁之间时
    if (age > 55 && age < 61) {
      const carType_56 = ['6', '7', '8', '9', '10']
      if (carType_56.indexOf(carId) != -1) {
        flag = false
        alert('提示', `当前申领年龄${age},即将达到上限，满60周岁后将会被注销相应准驾车型驾驶证，是否继续？`, [
          { text: '取消', onPress: () => { return } },
          { text: '继续', onPress: () => { onSubmitInfo(info) } },
        ])
      }
    }
    if (flag) {
      onSubmitInfo(info)
    }
  }

  // 走提交接口
  const onSubmitInfo = (info) => {
    firstStep(info).then((res) => {
      if (res.code == 'success') {
        const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
        const modifyInfo = { ...getregistrationInfo, ...res.data }
        sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
        props.stepClickHandler(1, res.data, info);
      } else {
        Toast.info(res.msg, 1);
      }
    }).catch(() => {
      return null
    })
  }

  // 下一步提交
  const onSubmit = () => {
    let flgNmber = 0
    const registrationInfo = props.form.getFieldsValue()
    console.log(registrationInfo);
    if (!registrationInfo.mobile || registrationInfo.mobile === '') {
      Toast.fail('请输入手机号码', 1);
    } else if (!registrationInfo.learningType || JSON.stringify(registrationInfo.learningType) === '[]') {
      Toast.fail('请选择学习类型', 1);
    } else if (!registrationInfo.carId || JSON.stringify(registrationInfo.carId) === '[]') {
      Toast.fail('请选择所学车型', 1);
    } else if (!registrationInfo.contactRegion || JSON.stringify(registrationInfo.contactRegion) === '[]') {
      Toast.fail('请检查行政区域', 1);
    } else if (!registrationInfo.contactAddress || registrationInfo.contactAddress === '') {
      Toast.fail('请输入联系住所详细地址', 1);
    } else if (!registrationInfo.zipCode || registrationInfo.zipCode === '') {
      Toast.fail('请输入邮政编码', 1);
    } else if (!forbid && (!registrationInfo.residenceNumber || registrationInfo.residenceNumber === '')) {
      Toast.fail('请输入暂住证编号', 1);
      flgNmber++
    }

    props.form.validateFields(() => {
      const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
      const registrationInfo = props.form.getFieldsValue()
      const stringArr = ['email', 'phone', 'residenceNumber']
      for (const key in registrationInfo) {
        if (!registrationInfo[key] && (stringArr.indexOf(key) === -1)) {
          flgNmber++
        }
      }
      if (flgNmber === 0) {
        const modifyInfo = { ...getregistrationInfo, ...registrationInfo }
        sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
        // 对提交的数据按接口要求格式化
        const info = {
          ...modifyInfo,
          typeId: modifyInfo.typeId.toString(),
          learningType: modifyInfo.learningType.toString(),
          carId: modifyInfo.carId.toString().split(',')[0],
          country: modifyInfo.country.toString(),
          sex: modifyInfo.sex.toString(),
          source: modifyInfo.source.toString(),
          birthday: modifyInfo.birthday.slice(0, 10),
          idnumEnd: modifyInfo.idnumEnd.slice(0, 10),
          idnumStart: modifyInfo.idnumStart.slice(0, 10),
          contactRegion: modifyInfo.contactRegion.toString().replace(/,/g, ":"),
          registeredRegion: modifyInfo.registeredRegion.toString().replace(/,/g, ":"),
          id: (modifyInfo.id || modifyInfo.studentId) || '',
          orgRegion: city,
          unionId: unionid,
        }
        // 提交前验证
        submitBefore(info)
      }
    });
  }

  // 上一步
  const lastStep = () => {
    const getregistrationInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
    const registrationInfo = props.form.getFieldsValue()
    const modifyInfo = { ...getregistrationInfo, ...registrationInfo }
    sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
    props.stepClickHandler(-1);
  }

  // 按钮组数组
  const btnsArr2 = [
    {
      name: '上一步',
      className: 'btn-last',
      onClick: function () {
        lastStep()
      },
    },
    {
      name: '下一步',
      className: 'btn-next',
      onClick: function () {
        onSubmit()
      },
    },
  ];

  const renderFromTableMobile = () => {
    const startRender = regionBySource && template
    return startRender ? <div>
      <LiCreateForm fromTable={fromTableMobile(template, codeForbid)} Props={props} />
      <LiCreateForm fromTable={fromTableContactRegion(regionBySource, forbid)} Props={props} />
      <div className='ipx'><RenderBtns arr={btnsArr2} /></div>
    </div> : null
  }

  return (
    <div className={styles['regis-info']}>
      {renderFromTableMobile()}
    </div>
  );
};
export default createForm()(Index);
