import React from 'react';
import { rootRoute } from "./router/routes";
import qs from 'qs'
import renderRoutes from "./router/renderRoutes";
import {
  // HashRouter,
  BrowserRouter,
} from 'react-router-dom'

import { sessionOut } from './utils/wxApi'

import 'antd-mobile/dist/antd-mobile.css';

const App: React.FC = () => {
  const search = window.location.search
  const query = qs.parse(search, { ignoreQueryPrefix: true })
  if (!query) {
    sessionOut()
  } else {
    localStorage.setItem('token', query.token as string)
    localStorage.setItem('orgId', query.orgId as string)

    if (query.region) {
      localStorage.setItem('X-region', query.region);
    }
  }

  return (
    <BrowserRouter>
      {renderRoutes(rootRoute)}
    </BrowserRouter>
  )
}
export default App;
