import React, { useState, useEffect, useCallback, useRef } from 'react'

// import { Toast, Modal } from 'antd-mobile'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import { getFileInfo } from '../../util/urlCreator'
import 'react-medium-image-zoom/dist/styles.css'
import SignDlg from '../../signDlg'
import Dialog from '@/components/dialog'
import { delImage, getImageFile } from '@/service/open'

import DelSVG from '@/static/images/del.svg'
import ZoomSVG from '@/static/images/zoom.svg'
import './center.css'

/**
 * 大车告知书签字 , 申告确认书
 * 签字由标品控件上传，这里接收处理后的结果
 * @param props
 * @returns
 */
function NoticeSign(props: Picture.UploadFile): JSX.Element {
  console.log('reload UsualSign', props)
  const { defaultImg, text, type, steps, submitUrl, tempFile, unionId, studentId, itemImgChange } = props
  const [img, setImg] = useState(defaultImg)
  const [fileId, setFileId] = useState(props.fileId)
  const [isZoomed, setIsZoomed] = useState(false)

  const dlgSign = useRef(null)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  useEffect(() => { // change &
    if (fileId) {
      loadImg(fileId)
    }
    return () => {
      console.log('image change and dlg close', dlgSign)
      dlgSign.current && dlgSign.current.close()
    }
  }, [img])

  // 之前有多张背景，所以用了列表。现在只有单张背景默认图
  const cameraList = () => {
    const _zoom = {
      isZoomed: isZoomed,
      onZoomChange: handleZoomChange,
      overlayBgColorStart: 'rgba(0, 0, 0, 0)',
      overlayBgColorEnd: 'rgba(0, 0, 0, 0.5)',
    }

    return img !== null ?
      <ControlledZoom {..._zoom}><img className="center-img" src={img} key={img} alt={text} /></ControlledZoom> : null
  }

  // 加载图片
  const loadImg = (fileId?: string) => {
    dlgSign.current && dlgSign.current.close()
    const src = getFileInfo(fileId)
    setImg(src)
  }

  // 选择拍照还是签字的图片, 不是拍照就是签字
  const renderFlag = () => {
    return fileId ? null : <div className="sign-flag" />
  }

  // 删除图片按钮
  const delteImg = () => {
    delImage({
      fileType: type,
      studentId,
      unionId,
    }).then(() => {
      setFileId('')
      setImg(defaultImg)
      itemImgChange(type, '')
    })
  }

  // 查看图片按钮
  const imgZoom = () => {
    setIsZoomed(true)
  }

  // 获取文件模板
  const getImgTemp = () => {
    let _url = `${tempFile}?`;
    if (tempFile.indexOf('?') > -1) {
      _url = `${tempFile}&`;
    }
    _url = _url + `unionId=${unionId}&studentId=${studentId}`;
    getImageFile(_url).then((res) => {
      const fileStr = `data:image/png;base64,${res.data}`
      dlgSign.current = showDialog(fileStr)
    })
  }

  const showDialog = (fileStr) => {

    const params = {
      fileStr,  // 签字这里是模板地址
      // n次拍摄时显示的标题, 子组件依赖此判断步骤次数
      type,
      steps: steps,
      fileIndex: 0,  // 图片索引，多次拍摄时，确定更新的位置, 0 开始
      studentId,
      submitUrl: submitUrl,  // 上传地址
      unionId,
      onSave: (fileId: string) => { // 拍摄完成
        setFileId(fileId)
        loadImg(fileId)
        itemImgChange(type, fileId)
      }
    }
    return Dialog(<SignDlg {...params} />)
  }

  // 处理组件流程
  const operType = () => {
    if (!fileId) {
      getImgTemp()
    }
  }

  // 构造处理区域, 按钮组等
  const renderOperArea = () => {
    const operBtns = [
      <a key="zoom" className="oper-btn oper-zoom" onClick={() => imgZoom()}><img className="oper-img" src={ZoomSVG} alt="放大" /><span className="oper-txt">放大</span></a>,
      <a key="del" className="oper-btn oper-del" onClick={() => delteImg()}><img className="oper-img" src={DelSVG} alt="删除" /><span className="oper-txt">删除</span></a>
    ]

    if (fileId) {
      return operBtns
    } else {
      return null
    }
  }

  return (
    <>
      <div className="imgload-bg" onClick={operType}>
        <div className="flex-center">
          <div className="block">
            {cameraList()}
            {renderFlag()}
          </div>
          {!fileId ? <span className="bg-txt">{text}</span> : null}
        </div>
      </div>
      <div className="oper-area">
        {renderOperArea()}
      </div>
    </>
  )
}

export default NoticeSign
