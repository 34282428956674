import request from '@/utils/request'


// 获取制证照套餐余量
export function getCurrentCountByOrgId(orgId: string):Promise<API.asyncEntity<number>> {
  return request.get(`/api/school/idPhotoCurrentCount?orgId=${orgId}`)
}

export function checkConsumePhotoByIdnumAndCarId(data: {
  idnum: string;
  carId: string;
  studentId: string;
}):Promise<API.asyncEntity<boolean>> {
  return request.post(`/api/school/checkIdPhotoPurchased`, {data})
}
