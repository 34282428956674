import React from 'react'

import Picture from './Picture'
import RequireText from '../item/requireText'

import '../item/index.css'



// tabs 中的 content 构造
// 分成两块，1. 要求文本 2. 中间背景
const PictureDetailItem: React.FC<{ fileId: string; requirement: Picture.requirementProps }> = (props): JSX.Element => {
  const { fileId, requirement } = props

  // 渲染需求文本
  const renderRequireText = () => <RequireText {...requirement} />

  return (
    <div className='tab-content flex-item'>
      {renderRequireText()}
      <div className="flex-item imgload-container flex-center">
        <Picture fileId={fileId} />
      </div>
    </div>)
}

export default PictureDetailItem
