import {
  extend,
  ExtendOptionsWithoutResponse,
  RequestOptionsInit,
} from 'umi-request';
import { Modal, Toast } from 'antd-mobile'
import {
  DOMAIN,
  // SF_DOMAIN,
  PIC_DOMAIN
} from './env'
import { sessionOut } from './wxApi'

// 是否在加载中
let isLoading = false

// loading池
// const loadingPooling = 0
let timer = null
let model = null

const options: ExtendOptionsWithoutResponse = {
  prefix: '', //  extendOptions 扩展后是单例，还是用完整路径
  timeout: 1000 * 30,
  getResponse: false,
  requestType: "json",
  credentials: 'include'
}

// 初步对 request 全局定义，并加入全局拦截器
const request = extend({
  ...options,
});

// const requestSF = extend({
//   ...options,
//   prefix: SF_DOMAIN
// })

// console.log(requestSF === request)  // 实例不同，但共享拦截器

const requestInterceptor = function (url, options: RequestOptionsInit) {
  // if(!isLoading) {
  //   timer = setTimeout(() => { // 低于300ms的请求不显示loading
  //     isLoading = true
  //     Toast.loading('加载中', 0);
  //   }, 300)
  // }
  Toast.loading('加载中', 0);
  // console.log('options: ', options, url)  // Authorization: `Bearer ${token}`, // Fixs: Bearer token
  // TODO: 接真实接口时要使用bear
  const { headers } = options
  const bearer: any = { // 组装 auth 到headers
    ...headers
  }
  const token = localStorage.getItem('token');
  const region = localStorage.getItem('X-region');
  if (token && token !== 'undefined') {
    bearer.Authorization = token;
  }
  let _url = ''
  // if(url.indexOf('/matting/') > -1) {
  //   _url = `${SF_DOMAIN}${url}`
  // } else
  if (url.indexOf('/goserver') > -1) {
    _url = `${PIC_DOMAIN}${url}`
  } else {
    _url = `${DOMAIN}${url}`
    // goserver 服务不需要传这个
    if (region) {
      bearer.region = region
    }
  }
  return {
    url: `${_url}`,
    options: {
      ...options,
      headers: bearer
    },
  }
}

const responseInterceptor = async function (resp: Response, opts): Promise<Response> {
  const data = await resp.clone().json()

  Toast.hide()

  const code = data.code;
  // 兼容go server
  if (code == 0 || code == '0000') {
    data.code = 'success'
  } else {
    data.code = 'fail'
  }
  if (data.code !== 'success') {
    if (code !== 999) {
      Modal.alert('错误', data.msg || data.error)
    }
    if (('' + code).indexOf('401') > -1 || data.msg.indexOf('长时间未保存') > -1) {
      localStorage.clear()
      sessionOut()
    }
    // 特殊code判断
    if (code === 999) {
      data.code = '999'
      return data;
    }
    return new Promise(() => null)
  } else {
    return data;
  }
}

request.interceptors.request.use(requestInterceptor);

request.interceptors.response.use(responseInterceptor);

export default request
// export {
//   requestSF
// }
