import request from '../../utils/request'

export function getSchoolList():Promise<API.asyncEntity<{
  id: string;       // 驾校id
  name: string;     // 机构名
  orgType: number;  // 驾校类型：4：总校，5：分校，6：虚拟分校
}[]>> {
  return request.get(`/api/statistics/schoolList`)
}

/**
 * 学员报名数量统计
 */
export function enrollmentStatistics(params: {
  startTime: string;
  endTime: string;
  orgId: string;   // 驾校id
  dateType: number;  // 0: 近7天；1：近30天； 7:月  ；8: 年；9：自定义
}):Promise<API.asyncEntity<{
  carType: string;
  count: number;
}[]>> {
  return request.get(`/api/statistics/studentRegistration`, {params})
}

/**
 * 学员培训情况
 */
export function trainingSituationStatistics(params: {
  orgId: string;   // 驾校id
  dateType: number;  // 0: 近7天；1：近30天； 7:月  ；8: 年；9：自定义
}):Promise<API.asyncEntity<{
  dateTime: string;
  completedStudentCount: number; // 办结学员人数
  licensingStudentCount: number; // 领证学员人数
}[]>> {
  return request.get(`/api/statistics/studentTraining`, {params})
}

/**
 * 学员考试合格率
 */
export function passRateStatistics(params: {
  orgId: string;   // 驾校id
  subject: number; //（科目：1：科目一；2：科目二；3：科目三；4：科目四）
  dateType: number;  // 0: 近7天；1：近30天； 7:月  ；8: 年；9：自定义
}):Promise<API.asyncEntity<{
  date: string;
  percentage: number;  // 4位有效数字 0.5555
}[]>> {
  return request.get(`/api/statistics/studentExamPassRate`, {params})
}

/**
 * 待考学员数量合计
 */
export function candidatesStatistics(params: {
  orgId: string;   // 驾校id
}):Promise<API.asyncEntity<{
  subject: string; // 科目
  count: number;
}[]>> {
  return request.get(`/api/statistics/candidates`, {params})
}

/**
 * 学员制证照数量
 */
export function licenseMakingStatistics(params: {
  orgId: string;   // 驾校id
  dateType: number;  // 0: 近7天；1：近30天；2:近半年； 7:月  ；8: 年；9：自定义
}):Promise<API.asyncEntity<{
  dateTime: string;
  chargeCount: number;  // 收费人数
  noChargeCount: number; // 未收费人数
}[]>> {
  return request.get(`/api/statistics/idPhoto`, {params})
}
