import { useEffect, useState } from 'react';
import routes from "../../router/routes";
import renderRoutes from "../../router/renderRoutes";
import { useHistory } from "react-router-dom";
import ComSteps from "../../components/steps/index";
import { getTemplateInfo } from '@/service/open'

import qs from 'qs'
import './index.css'

const steps = [
  {
    title: '身份信息',
  },
  {
    title: '登记信息',
  },
  {
    title: '影像化资料',
  }
]
// 路由数组
const paths = ["/signup/registration", "/signup/baseInfo", "/signup/uploadData"]
// 模拟小程序传参
// const paths = ["/signup/uploadData?token=eyJhbGciOiJIUzI1NiIsInppcCI6IkdaSVAifQ.H4sIAAAAAAAAACWNwQrCMBAFf0X2bEp2E5uknkQEL3pR8LypiURqlcaKIP67Ke5xmDf7geszQQPKSJIyGsGqbYX2HIQLFIUO1HqOJrJzMIfET2iwRoPO4ULOIY--rKmmWpO2ZK022k5izoXzPYvzkF6pvxTG47kwNFQhVrLC5e7uUxdmB448pCKE9-NfJ01qqrddCn35CKfNers6FmXMYdjzLUwh65Qsp1DD9weAAVrTyAAAAA.6Ft2FJxJoiFIEuGspZnSY5bcUBM7qBKUV4VLdkN1Fhw&orgId=262642482884749"]
// const paths = ["/signup/uploadData"]

const Index: React.FC = (props: any) => {
  const history = useHistory()
  const [template, setTemplate] = useState({})
  const [uploadNeedData, setUploadNeedData] = useState({})
  const [current, setCurrent] = useState(0)
  const hash = history.location.pathname;


  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { city = '' } = query || {};

  // 路由改变之后对应的current改变
  const getKey = (arr: Array<string>, value: string) => {
    let key = 0
    arr.forEach((element: string, index: number) => {
      if (element == value) {
        key = index
      }
    });
    return key
  }

  useEffect(() => {
    setCurrent(getKey(paths, hash))
  }, [hash])

  useEffect(() => {
    getTemplateInfo(city).then((res) => {
      if (res.code == 'success') {
        setTemplate(res.data);
        const mobileShow = res.data.mobile + ''
        sessionStorage.setItem('mobileShow', mobileShow);
      }
    }).catch(() => {
      return null
    })
  }, [])

  const changeStep = (currentNum: number) => {
    // if(currentNum === 0) {
    //   history.push(paths[currentNum])
    // } else {
    //   history.replace(paths[currentNum])
    // }
    history.replace(paths[currentNum] + props.location.search)
  }

  useEffect(() => {
    changeStep(current)
  }, [current])

  // 步数
  const stepHandler = (step = 1) => {
    return current + step
  }

  // 改变current
  const stepClickHandler = (step: number, firstStep?: School.Source, studentInfo?: Student.StudentInfo) => {
    const curr = stepHandler(step)
    setCurrent(curr);
    setUploadNeedData({
      firstStep,
      studentInfo
    })
  }

  return (
    <div className="app">
      <div className='steps-box'>
        <ComSteps step={steps} current={current} />
      </div>
      <div className='interval' />
      <div className="relative flex-item flex ipx">
        {renderRoutes(routes, { stepClickHandler, template, ...uploadNeedData })}
      </div>
    </div>
  )
}
export default Index;
