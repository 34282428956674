import React from "react";
import RequireText from "../item/requireText";

function WithRequire(Component) {
  return (props) => {
    const {requirement, ...otherProps} = props
    return (
      <div className='tab-content flex-item'>
        <RequireText {...requirement} />
        <Component {...otherProps} />
      </div>
    )
  }
}

export default  WithRequire
