import { EnumFileType } from '../data'
import { EnumCacheStrategy } from '@/utils/enumer'
import { DOMAIN } from '@/utils/env'

export function getImgServerUrl(id:string):string {
  const region = localStorage.getItem('X-region');
  return getServerUrl(`/api/fileInfo/download/picture?fileInfoId=${id}&x-region=${region}`);
}

export function getThumImgServerUrl(id:string):string {
  const region = localStorage.getItem('X-region');
  return getServerUrl(`/api/fileInfo/download/picture?fileInfoId=${id}&tag=2&x-region=${region}`);
}

export function queryUrl(url:string, search:Record<string, any>):string {
  const arrs = url.split('?');
  const newParams = [];
  let queries;
  if (arrs.length === 2) {
    queries = arrs[1].split('#');
    const oldParams = queries[0].split('&');
    oldParams.forEach((param) => {
      const itemParam = param.split('=');
      const key = itemParam[0];
      if (Object.prototype.hasOwnProperty.call(search, itemParam[0])) {
        if (search[itemParam[0]] !== undefined) {
          newParams.push(`${key}=${encodeURIComponent(search[key])}`);
        }
        delete search[key];
      } else {
        newParams.push(`${key}=${itemParam[1]}`);
      }
    });
  } else {
    queries = url.split('#');
  }
  for (const key in search) {
    if (search[key] !== undefined) {
      newParams.push(`${key}=${encodeURIComponent(search[key])}`);
    }
  }
  let _url = arrs[0];
  if (newParams.length > 0) {
    if (url.indexOf('?') > -1) {
      _url = `${url}&${newParams.join('&')}`;
    } else {
      _url = `${url}?${newParams.join('&')}`;
    }
  }
  if (queries.length > 1) {
    _url = `${url}#${queries[1]}`;
  }
  return _url;
}

export function getFileImgServerUrl(params: Record<string, any>):string {
  params._t = new Date().getTime();
  params._token = localStorage.getItem('token');
  let url = `/api/student/downloadImage`;
  url = queryUrl(url, params);
  return getServerUrl(url);
}

export function getEditStudentImgServerUrl(fileType: number, fingerIndex?: number):string {
  const params = {
    studentId: localStorage.STUDENT_EDIT_ID,
    tag: 2,
    fileType,
    fingerIndex,
    cache:
      fileType == EnumFileType.身份证电子照片
        ? EnumCacheStrategy.先获取缓存
        : EnumCacheStrategy.只获取缓存,
  };
  return getFileImgServerUrl(params);
}

export function getServerUrl(url:string): string {
  let _url = url
  if (!/https?:/.test(url) && !url.startsWith(DOMAIN)) {
    _url = `${DOMAIN}${url}`
  }
  return _url;
}

export function getFileInfo(fileInfoId: string): string {
  const region = localStorage.getItem('X-region');
  return `${DOMAIN}/api/fileInfo/download/picture?fileInfoId=${fileInfoId}&tag=2&x-region=${region}`
}


// 查看详情时， 电子照片的调用地址
export function downloadThumbnail(fileInfoId: string): string {
  const region = localStorage.getItem('X-region');
  return `${DOMAIN}/api/fileInfo/download/picture?fileInfoId=${fileInfoId}&x-region=${region}`
}
