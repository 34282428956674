import React from "react";
import { Chart, Axis, Geom, Tooltip } from 'bizgoblin';

function PercentLine({data = [], dataKeys, padding = [30,30,20,50]}) {
  const renderAxis = () => {
    return dataKeys.map(item => <Axis key={item} dataKey={item} />)
  }

  const defs = [{
    dataKey: 'percentage',
    min: 0,
    formatter(value) {
      return `${(value * 100).toFixed(2)}%`;
    },
  }]

  function onShowTooltip(ev) {
    const items = ev.items;
    items[0].name = null;
    items[0].name = items[0].title + '考试合格率';
    items[0].value = ` ${items[0].value}`;
  }

  const position = dataKeys.join('*')

  return (
    <Chart width="100%" data={data} defs={defs} pixelRatio={window.devicePixelRatio * 2} padding={padding} >
      {renderAxis()}
      <Tooltip showCrosshairs onShow={onShowTooltip} />
      <Geom geom="line" position={position}  />
      <Geom geom="point" position={position}  style={{ lineWidth: 1, stroke: '#FFF' }} />
    </Chart>
  )
}

export default PercentLine
