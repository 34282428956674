import React from "react";
import { Chart, Axis, Geom, Coord, Global, Tooltip } from 'bizgoblin';

function TotalBar({data}) {
  const defs = [{
    dataKey: 'carType',
    tickCount: 5
  }, {
    dataKey: 'count',
    min: 0,
    type: 'linear',
    minTickInterval: 1,
    formatter(value) {
      return `${value} 人`;
    },
  }]

  function formatLabel(text, index, total) {
    const textCfg = {textAlign: ''};
    if (index === 0) {
      textCfg.textAlign = 'left';
    } else if (index === total - 1) {
      textCfg.textAlign = 'right';
    }
    return textCfg;
  }

  function onShowTooltip(ev) {
    const items = ev.items;
    items[0].name = null;
    items[0].name = items[0].title;
    items[0].value = ` ${items[0].value}`;
  }

  return (
    <Chart width="100%" data={data} defs={defs} pixelRatio={window.devicePixelRatio * 2} padding={[30,30,20,30]} >
      <Axis dataKey="carType" grid={null} />
      <Axis dataKey="count" label={formatLabel} line={null} grid={Global._defaultAxis.grid} />
      <Coord transposed />
      <Tooltip showItemMarker={false} onShow={onShowTooltip} />
      <Geom geom="interval" position="carType*count" />
    </Chart>
  )
}

export default TotalBar
