import React, { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom'
import { Tabs } from '../../components/tabs';
import type { PropsType, TabBarPropsType } from '../../components/tabs'
import LiBtns from "../../components/FlexBtn/index";
import { sortTypeList, EnumFileType } from './data'
import UploadItem from './item'
import { confirm } from '@/service/open'
import { backMiniWork } from '@/utils/wxApi'

import styles from './index.module.css';
import { Toast } from 'antd-mobile';
import { getCertificatePhotoId } from '../../service/open';
import qs from 'qs';

export interface TabData {
  key?: string;
  title: React.ReactNode;

  /** for user's custom extends */
  [key: string]: unknown;
}

const Index: React.FC<{
  template: School.Template;
  firstStep: API.ImageFileRenderCondition;
  studentInfo: Student.StudentInfo;
  mode: number; // 0查看  1编辑
}> = (props: any) => {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { unionid = '' } = query || {};

  const history = useHistory()
  const { studentId, fileList } = props.firstStep
  localStorage.STUDENT_EDIT_ID = studentId
  const centerImgRef = useRef({});

  const {
    template,
    firstStep,
    studentInfo,
    // mode,
  } = props

  // 为了后端把学员数据加入缓存而刷新证件照，否则无法签字
  useEffect(() => {
    getCertificatePhotoId({ studentId, unionId: unionid }).then()
  }, [])

  // 页面btn 构造函数, 查看时不渲染
  const renderBtns = () => {
    const btns = [
      {
        name: '上一步', className: 'btn-last', onClick: () => {
          history.replace('/signup/baseInfo' + props.location.search)
        }
      },
      {
        name: '提交', className: 'btn-next', onClick: function () {
          confirm({ studentId, unionId: unionid }).then(() => {
            Toast.success('提交学员成功', 3, () => {
              history.push('/student?unionid=' + unionid);
            })
          })
        }
      },
    ]

    // return mode === 0 ? <LiBtns arr={btns} /> : null
    return <LiBtns arr={btns} />
  }

  const { templateFileList, avatarSource } = template

  // 页面接收由后端返回的电子文档列表，和静态比较 (data.ts -> typeMap), 根据业务选择需要渲染的列表
  const renderTabs = () => {
    let tablist = [...templateFileList]

    const { residenceFile, notificationFile } = firstStep
    if (residenceFile) {
      tablist.push({
        type: EnumFileType.暂住证,
        status: 1,
      });
    }
    if (notificationFile) {
      tablist.push({
        type: EnumFileType.办理申请大中型客货车驾驶证业务告知书,
        status: 1,
      });
    } else {// 模板可能和实际不符，需要做减法
      tablist = tablist.filter(
        (item) =>
          item.type !== EnumFileType.办理申请大中型客货车驾驶证业务告知书
      );
    }

    const { learningType } = studentInfo

    // 如果是增驾才需要填原驾驶证
    tablist = tablist.filter(
      (item) => {
        if (item.type != EnumFileType.原驾驶证) {
          return true
        } else if (learningType != 0) {
          return true
        }
        return false
      }
    );

    // 合并fileId 到列表，用于编辑
    const tempArr = tablist.length ? sortTypeList(tablist) : []
    fileList.length && tempArr.map(item => {
      for (const para of fileList) {
        if (para.type === item.type) {
          item.fileId = para.fileId || ''
        }
      }
    })
    return [...tempArr]
  }

  const tabs = templateFileList ? renderTabs() : []

  const tabsProps = (): PropsType => {
    return {
      tabs: tabs,
      tabBarPosition: "left",
      swipeable: true,
      destroyInactiveTab: true,
      tabDirection: 'vertical',
      tabBarBackgroundColor: '#f0f0f0',
      renderTabBar: (params: TabBarPropsType) => <Tabs.DefaultTabBar {...params} page={8} />
    }
  }

  const renderContent = () => tabs.map((item) => {
    console.log(item)
    const { carId, idnum } = studentInfo
    const { studentId, reSign, confirmationReSign, notificationReSign } = firstStep
    const _props = {
      studentId,
      avatarSource,
      carId: carId.toString(),
      idnum,
      centerImgRef: centerImgRef.current,
      unionId: unionid,
      ...item,
    }
    // 需重签 机动车驾驶证申请表/注册申请表(互联网个人用户注册/变更申请表)/申告确认书，
    // 本来在渲染具体子组件时处理，但跟新文件的Id反复重置fileId, 把其处理提升
    if (reSign && (item.type === EnumFileType.驾驶证申请表 || item.type === EnumFileType.注册申请表)) {
      _props.fileId = ''
    }
    if (notificationReSign && item.type === EnumFileType.办理申请大中型客货车驾驶证业务告知书) {
      _props.fileId = ''
    }
    if (confirmationReSign && item.type === EnumFileType.申告确认书) {
      _props.fileId = ''
    }
    return <UploadItem {..._props} key={item.type} />
  })

  return (
    <div className={styles.tabsMain}>
      <Tabs {...tabsProps()}>
        {renderContent()}
      </Tabs>
      <div className={styles.pictureBtns}>
        {renderBtns()}
      </div>
    </div>
  )
}
export default Index;
