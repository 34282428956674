/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const EnumInternetStatus = {
  预录入: 0,
  已保存: 1,
  已提交至总校: 2,
  待反馈上传结果: 3,
  上传成功: 4,
  制证照审核不通过: 5,
  getText(value) {
    return this[value];
  }
};

const EnumInnerStatus = {
  办结: 4,
  退办: 5,
  待复核: 6,
  待制证: 8,
  待归档: 10,
  已归档: 12,
  退学: 13,
  getText(value) {
    return this[value];
  },
};
const  EnumSex = {
  男:0,
  女:1,
}

const EnumCacheStrategy = {
  先获取缓存: 1,
  不获取缓存: 2,
  只获取缓存: 3,
};

export {
  EnumInternetStatus,
  EnumInnerStatus,
  EnumCacheStrategy,
  EnumSex,
}
