import request from '../../utils/request'

/**
 * 通用提交接口，multipart/form-data
 * 提交文件的超时时间为 2m
 * @param url
 * @param data {
  studentId: string;
  fileType: number;
  [key: string]: string | number;
}
 * @returns
 */
export function commitFile(url: string, data: FormData):Promise<API.asyncEntity<string>> {
  return request.post(url, {data, timeout: 2*60*1000})
}

/**
 * 提交证件照，返回值和其他接口不一致
 * @param url
 * @param data
 */
export function commitFileByCert(url: string, data: FormData):Promise<API.asyncEntity<{
  count: number;
  photoService: number;
  fileId: string;
}>> {
  return request.post(url, {data, timeout: 2*60*1000})
}

/**
 * 抠图等操作图片，入参为 json, 返回 文件base64
 * @param data [base64_pic]
 */
export function operationPicture(data: string[]):Promise<API.asyncEntity<string>> {
  return request.post(`/goserver/getImg`, {data, timeout: 2*60*1000, credentials: "omit"})
}


/**
 * 提交需抠图等操作的图片，入参为 json, 返回 文件ID
 * @param data {
 *   studentId 学员Id
      fileType 文件类型
      base64 图片的base64
 * }
 */
export function commitSpecialPic(data: {
  studentId: string;
  fileType: number;
  base64: string;
}):Promise<API.asyncEntity<string>> {
  return request.post(`/api/student/uploadImageBase64`, {data, timeout: 2*60*1000})
}

export function getPicPoints(data: {
  fileData: string;
  id: string;
}):Promise<API.asyncEntity<Picture.point[]>> {
  return request.post(`/goserver/getPoints`, {data, timeout: 2*60*1000, credentials: "omit"})
}

export function getPicBegin(data: {
  points: Picture.point[];
  id: string;
}):Promise<API.asyncEntity<null>> {
  return request.post(`/goserver/getPtImgBegin`, {data, timeout: 2*60*1000, credentials: "omit"})
}

export function getPicEnd(data: {
  points: Picture.point[];
  id: string;
}):Promise<API.asyncEntity<string>> {
  return request.post(`/goserver/getPtImgEnd`, {data, timeout: 2*60*1000, credentials: "omit"})
}
