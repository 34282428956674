export const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
export const WINDOW = IS_BROWSER ? window : null
export const IS_TOUCH_DEVICE = IS_BROWSER && window.document.documentElement ? 'ontouchstart' in window.document.documentElement : false;
export const HAS_POINTER_EVENT = IS_BROWSER ? 'PointerEvent' in window : false;
export const NAMESPACE = 'zcropper';

export const polygonColor = 'red'
export const circleColor = 'blue'

// Events

export const EVENT_TOUCH_START = IS_TOUCH_DEVICE ? 'touchstart' : 'touchstart'; //mousedown
export const EVENT_TOUCH_MOVE = IS_TOUCH_DEVICE ? 'touchmove' : 'touchmove'; // mousemove
export const EVENT_TOUCH_END = IS_TOUCH_DEVICE ? 'touchend' : 'touchend'; //mouseup
export const EVENT_POINTER_DOWN = HAS_POINTER_EVENT ? 'pointerdown' : EVENT_TOUCH_START;
export const EVENT_POINTER_MOVE = HAS_POINTER_EVENT ? 'pointermove' : EVENT_TOUCH_MOVE;
export const EVENT_POINTER_UP = HAS_POINTER_EVENT ? 'pointerup pointercancel' : EVENT_TOUCH_END;

