import React, {useCallback, useState} from "react";

import { Calendar } from 'antd-mobile'
import {formatDate} from '@/utils/util'

import styles from './index.module.less'

/**
 * 0: 近7天；1：近30天；2：近半年 7:月  8：年  9：自定义
 * */
function TimeSetting(props:Statistics.TimeSettingProps) {
  const {selectedChange, items, title, hasCustomer} = props
  const [selected, setSelected] = useState(items[0].id)
  const [showCalendar, setShowCalendar] = useState(false)

  const renderItem = (items) => {
    return  items.map((item) => {
      return (<span key={item.id} className={`${styles.timeItem} ${selected === item.id ? styles.selected : ''}`} onClick={() => itemClick(item)}>{item.name}</span>)
    })
  }

  const itemClick = (item) => {
    console.log(item)
    if(hasCustomer && item.id === 9) {
      setShowCalendar(true)
      return
    }
    setSelected(item.id)
    selectedChange && selectedChange(item)
  }

  const calendarCancel = () => {
    setShowCalendar(false)
  }

  const calendarComfirm = (sTime, eTime) => {
    const startTime = formatDate(sTime, 'yyyy-MM-dd')
    const endTime = formatDate(eTime, 'yyyy-MM-dd')
    selectedChange && selectedChange({
      startTime,
      endTime,
      id: 9,
      name: '',
    })
    setShowCalendar(false)
    setSelected(9)
  }

  const renderCalendar = useCallback(() => {
    return hasCustomer && (
      <Calendar
        visible={showCalendar}
        onCancel={calendarCancel}
        onConfirm={calendarComfirm}
        minDate={new Date('2020-01-01')}
        maxDate={new Date()}
      />
    )
  }, [showCalendar, hasCustomer])

  return (
    <div className={styles.timeSetting}>
      <div className={styles.title}>{title}</div>
      <div className={styles.items}>
        {renderItem(items)}
        {renderCalendar()}
      </div>
    </div>
  )
}

export default TimeSetting
