import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";

import {
  EVENT_TOUCH_START,
  EVENT_TOUCH_MOVE,
  EVENT_TOUCH_END,
  polygonColor,
  circleColor
} from './constants';

import styles from './index.module.less'

const radius = 10

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ZCropper(props) {
  console.log('ZCropper props', props)
  const {points, image, pointChange} = props
  const [cropperImgProps, setCropperImgProps] = useState({})
  const [maskBoxProps, setMaskBoxProps] = useState({})
  // const [testStr, setTestStr] = useState('')
  const [maskProps, dispatch] = useReducer((state, {type, payload}) => {
    if(type === 'init') {
      return {...payload}
    } else if (type === 'move') {
      return {
        ...state,
        style: {
          ...state.style,
          ...payload
        }
      }
    }
  }, {})

  // 引用当前操作的svg圆
  const currPointRef = useRef({x: 0, y: 0})
  const dataRef = useRef(null)
  const svgRef = useRef(null)
  const pointsRef = useRef([]) // 缩放后的点
  const circleRef = useRef([])
  const polygonRef = useRef(null)
  const oldPointsRef = useRef([]) // 原比例点

  useEffect(() => {
    pointsRef.current = [...points]
    oldPointsRef.current = [...points]
  }, [points])

  useEffect(() => {
    const svg = svgRef.current
    svg && svg.addEventListener(EVENT_TOUCH_END, mouseupHandler)
    return function cleanup() {
      const svg = svgRef.current
      svg && svg.removeEventListener(EVENT_TOUCH_END, mouseupHandler)
    }
  }, [])

  const containerRef = useCallback( node => {
    if(node !== null) { // 设置容器宽高
      const rect = node.getBoundingClientRect()
      console.log(rect)
      dataRef.current = {
        containerWidth: rect.width,
        containerHeight: rect.height,
        top: rect.top,
        left: rect.left
      }
    }
  }, [])

  // 隐藏的图片，来加载到页面后获取图片的宽高
  const imgRef = useCallback( img => {
    if(img) {
      img.onload = (e) => {
        console.log('img onload', e, img.width, img.height, )
        const imgWidth = img.width
        const imgHeight = img.height
        const containerWidth = dataRef.current.containerWidth - 80
        const containerHeight = dataRef.current.containerHeight - 80

        const scaleW = containerWidth/imgWidth  // 宽度压缩比， 按宽度和容器同宽
        const scaleH = containerHeight/imgHeight // 高度压缩比
        let scale = scaleW
        if(scaleW > scaleH) { // 如果高度压缩的比较多，那按多的来
          scale = scaleH
        }
        const realW = imgWidth * scale
        const realH = imgHeight * scale
        const offsetX = (containerWidth - realW) / 2
        const offsetY = (containerHeight - realH) / 2
        const style = {
          width: realW + 'px',
          height: realH + 'px',
          transform: `translateX(${offsetX}px) translateY(${offsetY}px)`,
          display: 'block',
        }
        const _offsetX = offsetX + dataRef.current.left + 40  // 偏移量要加上padding
        const _offsetY = offsetY + 40
        dataRef.current = {
          ...dataRef.current,
          scale,
          imgWidth,
          imgHeight,
          offsetX: _offsetX,
          offsetY: _offsetY,
          offsetBottomX: _offsetX + realW,
          offsetBottomY: _offsetY + realH
        }
        setCropperImgProps({
          style,
          src: img.src
        })

        dispatch({
          type: 'init',
          payload: {
            style: {
              width: realW + 'px',
              height: realH + 'px',
            },
            src: img.src
          }
        })

        const newPoints = []
        for(let i = 0; i < points.length; i++) {
          const p = points[i]
          const _x = p.x*scale + _offsetX
          const _y = p.y*scale + _offsetY
          newPoints.push({
            x: _x,
            y: _y
          })
        }
        pointsRef.current = newPoints

        setSVGElement(newPoints)
      }
    }
  }, [points])

  function setSVGElement(pointArr) {
    const node = svgRef.current
    if(node) {
      node.innerHTML = ''
    }
    renderPolygon(node, pointArr)
    circleRef.current = []
    for(let i = 0; i < pointArr.length; i++) {
      renderCircle(node, pointArr[i], i)
    }
  }

  function createPolygonPath(points) {
    console.log('createPolygonPath', points)
    const _path = []
    for (let i = 0; i < points.length; i++) {
      _path.push(`${points[i].x},${points[i].y}`)
    }
    return _path
  }

  function mousemoveHandler(e) {
    const index = parseInt(e.target.getAttribute('zzz'))
    const circle = circleRef.current[index]
    if(!circle) {
      return;
    }
    // 移动元素的当前坐标
    const aimX = pointsRef.current[index].x
    const aimY = pointsRef.current[index].y

    console.log(`cx: ${circle.getAttribute('cx')}, cy: ${circle.getAttribute('cy')}, aimX: ${aimX}, aimY: ${aimY}`)
    // 触发时的初始值
    const initX = currPointRef.current.x
    const initY = currPointRef.current.y
    // console.log(e, circle.getAttribute('cx'), circle.getAttribute('cy'), dataRef)
    if (e.changedTouches) {
      const container = dataRef.current
      const leftSideX = container.offsetX
      const rightSideX = container.offsetBottomX
      const topSideY = container.offsetY
      const bottomSideY = container.offsetBottomY
      const polygon = polygonRef.current

      const scale = dataRef.current.scale

      // Handle touch event
      for(const touch of e.changedTouches){
        // console.log(touch, touch.clientY, touch.pageY, container.offsetY, topSideY)
        // alert(`${container.offsetY}, ${touch.pageY}, ${topSideY}, ${scale}`)
        const changeY = touch.pageY - initY
        const changeX = touch.pageX - initX
        let offsetX = aimX + changeX
        if( offsetX < leftSideX) { // 当移动到边界时，阻止
          offsetX = leftSideX
        }
        if(offsetX > rightSideX) {
          offsetX = rightSideX
        }
        let offsetY = aimY + changeY
        if(offsetY < topSideY) {
          offsetY = topSideY
        }
        if(offsetY > bottomSideY) {
          offsetY = bottomSideY
        }
        circle.setAttribute('cx', offsetX)
        circle.setAttribute('cy', offsetY)
        // setTestStr(`clientY: ${touch.clientY}-------pageY: ${touch.pageY}----cy: ${circle.getAttribute('cy')}----offsetY: ${offsetY}`)

        pointsRef.current[index] = {
          x: offsetX,
          y: offsetY
        }

        // 更新初始点为最新点
        currPointRef.current = {
          x: touch.pageX,
          y: touch.pageY
        }
        const _path = createPolygonPath(pointsRef.current)
        polygon.setAttribute('points', _path.join(' '))

        dispatch({
          type: 'move',
          payload: {
            left: -(offsetX - leftSideX - 30) + 'px',
            top: -(offsetY - topSideY - 30) + 'px'
          }
        });

        const oldOffsetX = (offsetX-leftSideX)/scale
        const oldOffsetY = (offsetY-topSideY)/scale
        oldPointsRef.current[index] = {
          x: oldOffsetX,
          y: oldOffsetY
        }

        pointChange && pointChange(oldPointsRef.current)
      }
    }
  }

  function mouseupHandler() {
    console.log('drag end')
    currPointRef.current = {x: 0, y: 0}
    const svg = svgRef.current
    setMaskBoxProps({display: 'none'});
    svg && svg.removeEventListener(EVENT_TOUCH_MOVE, mousemoveHandler)
  }

  function touchStartHandler(e) {
    console.log('touchStartHandler', e)
    if(e.target && e.targetTouches.length === 1) {
      currPointRef.current = {
        x: e.targetTouches[0].pageX,
        y: e.targetTouches[0].pageY,
      }
    }
    const svg = svgRef.current
    svg && svg.addEventListener(EVENT_TOUCH_MOVE, mousemoveHandler)
    svg && svg.addEventListener(EVENT_TOUCH_END, mouseupHandler);
    const cx = this.getAttribute('cx')
    const cy = this.getAttribute('cy')
    if(cx && cy) {
      const container = dataRef.current
      const leftSideX = container.offsetX
      const topSideY = container.offsetY
      dispatch({
        type: 'move',
        payload: {
          left: -(parseFloat(cx) - leftSideX - 30) + 'px',
          top: -(parseFloat((cy)) - topSideY - 30) + 'px'
        }
      });
    }
    setMaskBoxProps({display: 'block'});
  }

  const renderPolygon = (svg, points) => {
    console.log('renderPolygon', points)
    const polygonElement = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
    polygonElement.setAttribute("stroke-width", '2');
    polygonElement.setAttribute("stroke", polygonColor);
    polygonElement.setAttribute('fill', 'transparent')
    const _path = createPolygonPath(points)
    polygonElement.setAttribute('points', _path.join(' '))

    svg.appendChild(polygonElement)
    polygonRef.current = polygonElement
  }

  // 构造可拖动的环
  const renderCircle = (svg, point, index) => {
    const circleElement = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circleElement.setAttribute("fill", 'transparent');
    circleElement.setAttribute("zzz", index);
    circleElement.setAttribute("stroke-width", '2');
    circleElement.setAttribute("stroke", circleColor);
    circleElement.setAttribute("r", radius + '');
    circleElement.setAttribute("cx", point.x);
    circleElement.setAttribute("cy", point.y);

    circleElement.addEventListener(EVENT_TOUCH_START, touchStartHandler);

    svg.appendChild(circleElement);
    circleRef.current.push(circleElement)
  }

  return (
    <div ref={containerRef} className={[styles.container, styles.bg].join(' ')}>
      {/*<div className={styles.test}>{testStr}</div>*/}
      <img ref={imgRef} className={styles.imgHidden} src={image} alt="cropper"/>
      <div className={styles.boxBg} style={maskBoxProps}>
        <div className={styles.center}></div>
        <img className={styles.boxImg} {...maskProps} alt="cropper"/>
      </div>
      <div className={styles.wrapbox}>
        <img className={styles.imgHidden} {...cropperImgProps} alt="cropper"/>
      </div>
      <svg ref={svgRef} className={styles.cropperSVG} version="1.1"
           xmlns="http://www.w3.org/2000/svg" >
      </svg>
    </div>
  )
}

export default ZCropper
