import React, {useState} from "react";

function DropdownSubject(Component) {
  return ({dropdownChange}) => {
    const data = [
      {
        key: 0,
        label: '全部',
        value: '',
      },
      {
        key: 1,
        label: '科目一',
        value: 1,
      },
      {
        key: 2,
        label: '科目二',
        value: 2,
      },
      {
        key: 3,
        label: '科目三',
        value: 3,
      },
      {
        key: 4,
        label: '科目四',
        value: 4,
      }
    ]
    const componentProps = {
      title: '科目',
      dropdownChange,
      data,
    }
    return (
      <Component {...componentProps} />
    )
  }
}

export default DropdownSubject
