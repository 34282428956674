import React from 'react';
import './index.css';

type btnObj = {
  onClick: (e: Event) => unknown;
  className?: string;
  name: string;
}

export type btnProps = btnObj | JSX.Element

const LiBtns: React.FC<{ arr: Array<btnProps> }> = (props): JSX.Element => {
  // arr为按钮数组 item.click为函数事件 item.className按钮样式类名
  const { arr } = props;

  const renderChildren = () => {
    return arr && arr.map((item: btnProps, index: number) => {
      if (item && item['name']) {
        const btn = {
          onClick: item['onClick'],
          className: item['className'] || 'btn-next',
          name: item['name']
        }
        return <div className="flex-item" key={index}><button {...btn}>{btn.name}</button></div>
      }
      return <div className="flex-item" key={index}>{item}</div>
    })
  }

  return (
    <div className="flex">
      {renderChildren()}
    </div>
  )
}

export default LiBtns
