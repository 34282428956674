import { InputItem,Toast } from 'antd-mobile';
import { useState } from 'react'
import { getSendSmsCode } from '@/service/erp/system'
import style from '../createForm/index.module.css';

const LiInputItem = (props) => {
  const [initExtra,setInitExtra] = useState('获取验证码')
  const { item,Props} = props
  const { getFieldProps, getFieldError,getFieldValue } = Props.form
  const mobileShow = sessionStorage.getItem('mobileShow')
  // 必填 带星号
  const requiredSpan = (name)=>{
    return (
      <span className='from-label'><span className={style['red']}>*</span>{name}</span>
    )
  }
  // 非必填
  const span = (name)=>{
    return (
      <span className='from-label'>{name}</span>
    )
  }

  // 倒计时
  const timer = () =>{
    let count = 60
      let interval = setInterval(() => {
        count--
        if (count < 1) {
          clearInterval(interval)
          setInitExtra('获取验证码')
        } else {
          setInitExtra(count + ' 秒后再试')
        }
      }, 1000);
  }

  // 点击获取验证码触发事件
  const onExtraClick = () =>{
    const mobileValue = getFieldValue('mobile')
    // 手机号码验证
    const REGEX_MOBILE = /^1[3456789]\d{9}$/;
    if (REGEX_MOBILE.test(mobileValue)) {
      getSendSmsCode({
        mobile: mobileValue
      }).then((res)=>{
        if (res.code == 'success') {
          setInitExtra(60 + ' 秒后再试')
          timer()
          let modifyInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
          modifyInfo = {...modifyInfo,mcodeKey:res.data}
          sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
          Toast.success('发送成功，请查收短信', 1);
        } else {
          Toast.fail(res.msg, 2);
        }
      }).catch(()=>{
        return null
      })
    } else {
      Toast.fail('请检查手机号码是否正确', 2);
    }
  }
  return (
    !(item.valueName =='mcode' && (mobileShow =='2' || item.disabled)) ?
    <InputItem className={style['am-list-item']}
      {...getFieldProps(item.valueName, {
        rules: [
          {validator:(item.required? (rule, value, callback) => item.verify(rule, value, callback, Props):{})},
        ],
        initialValue:''
      })}
      error={!!getFieldError(item.valueName)}
      onErrorClick={() => {
        Toast.fail(getFieldError(item.valueName), 1);
      }}
      clear
      disabled={item.forbid}
      maxLength={item.maxLength || 50}
      extra={<div className={style["code"]}>{(item.extra && !item.forbid) ? initExtra : ''}</div>}
      onExtraClick={(initExtra == item.extra) ? onExtraClick : null}
      type="text"
      editable={!item.disabled}
      placeholder={item.placeholder}
      >
      {item.required?requiredSpan(item.textName):span(item.textName)}
    </InputItem> : null
  )
}

export default LiInputItem
