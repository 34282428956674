import React, { useState, useCallback } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { downloadThumbnail } from '../util/urlCreator'


import '../item/center/center.css'


/**
 * 查看详情时的电子照片显示
 */
function Picture(props: { fileId }): JSX.Element {
  const { fileId } = props
  const [isZoomed, setIsZoomed] = useState(false)

  // 加载图片
  const loadImg = (fileId?: string) => {
    return downloadThumbnail(fileId)
  }

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  // 查看图片按钮
  const imgZoom = () => {
    setIsZoomed(true)
  }

  // 构造背景
  const cameraList = () => {
    const _zoom = {
      isZoomed: isZoomed,
      onZoomChange: handleZoomChange,
      overlayBgColorStart: 'rgba(0, 0, 0, 0)',
      overlayBgColorEnd: 'rgba(0, 0, 0, 0.5)',
    }

    const img = loadImg(fileId)

    return img !== null ?
      <ControlledZoom {..._zoom}><img className="center-img" src={img} key={fileId} onClick={() => imgZoom()} /></ControlledZoom> : null
  }

  return (
    <>
      <div className="imgload-bg">
        <div className="flex-center">
          <div className="block">
            {cameraList()}
          </div>
        </div>
      </div>
    </>
  )
}

export default Picture
