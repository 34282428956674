import request from '../../utils/request'


// 学员基础详情
export function getStudentBaseInfo(params: Student.StudentId): Promise<API.asyncEntity<Student.StudentInfo>> {
  return request.get('/api/student/baseInfo', { params })
}

// 查看详情
export function getStudentInfo(params: Student.StudentId): Promise<API.asyncEntity<Student.StudentInfo>> {
  return request.get('/api/student/details', { params })
}

// 学员分页查询
export function queryByPage(params: {
  pageIndex: number;
  pageSize: number, keys: string,
}): Promise<API.asyncEntity<API.asyncPage<Array<Student.mobileStudentInfo>>>> {
  return request.get('/api/student/list', { params })
}

// 保存学员
export function confirm(data: Student.StudentId): Promise<API.asyncEntity<Record<string, unknown>>> {
  return request.post(`/api/student/submit?studentId=${data.studentId}`)
}

// 保存或更新学员第一步(点击下一步就保存学员到数据库)
export function firstStep(data: Student.StudentId): Promise<API.asyncEntity<School.Source>> {
  return request.post('/api/student/saveBaseInfo', { data })
}

// 验证是否存在电子文件
export function checkFileExist(params: {
  fileType: number;
  studentId: string;
  cache: number;
}): Promise<API.asyncEntity<boolean>> {
  return request.get('/api/student/checkImageExist', {params})
}

// 同步证件照到签字头像
export function sycPassportPhotoToHeadPhoto(data: Student.StudentId):Promise<API.asyncEntity<null>> {
  return request.post(`/api/student/syncIdPhotoToHeadPhoto?studentId=${data.studentId}`)
}

// 获取制证照ID, 制证照的Id不在firstStep的fileList返回，需单独查询
export function getCertificatePhotoId(data: Student.StudentId):Promise<API.asyncEntity<string>> {
  return request.get(`/api/student/idPhotoFileId?id=${data.studentId}`)
}

// 删除图片
export function delImage(data: {
  fileType: number;
}& Student.StudentId):Promise<API.asyncEntity<null>> {
  return request.post(`/api/student/deleteImage?studentId=${data.studentId}&fileType=${data.fileType}`)
}

// 删除证件照
export function reCertificatePhoto(data: Student.StudentId):Promise<API.asyncEntity<null>> {
  return request.post(`/api/student/deleteImage?studentId=${data.studentId}&fileType=15&source=idPhoto`)
}
