import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Toast } from 'antd-mobile'

import Camera from '../../camera'
import PreviewDlg from '../../previewDlg'
import { EnumFileType } from '../../data'
import { commitFile } from '@/service/file/index'
import { getFileInfo, getEditStudentImgServerUrl } from '../../util/urlCreator'
import { compressImgToBlob } from '../../util/operImg'
import { delImage } from '@/service/open'
import Dialog from '@/components/dialog'

import DelSVG from '@/static/images/del.svg'
import ZoomSVG from '@/static/images/zoom.svg'
import './center.css'


/**
 * 通用拍照 含 现场照     TODO：注意在此补充处理的类型
 * 上传的照片不需要经过各种加工，所以利用上传前的缓存展示，需要根据 studentId 和 fileType 到服务器查询
 *
 * 这里统筹文件并上传
 * 整体有四层 1. 灰色背景  2. 触发开启相机的透明遮罩 3. 背景取景框  4. 根据拍照次数和类型决定的背景图  4. 相机图片
 */
function UsualCamera(props: Picture.UploadFile): JSX.Element {
  console.log('reload UsualCamera', props)
  const { defaultImg, text, type, steps, submitUrl, studentId, unionId, title, itemImgChange } = props
  const [img, setImg] = useState(defaultImg)
  const [fileId, setFileId] = useState(props.fileId)
  const [isZoomed, setIsZoomed] = useState(false)

  // 预览弹窗的接收变量，用于关闭弹窗
  const dlgPre = useRef(null)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  useEffect(() => { // change &
    if (fileId) {
      loadImg(fileId)
    }
    return () => {
      console.log('image change and dlg close', dlgPre)
      dlgPre.current && dlgPre.current.close()
    }
  }, [img])

  // 加载图片
  const loadImg = (fileId?: string) => {
    let src: string
    if (!fileId) {
      src = getEditStudentImgServerUrl(type);
    } else {
      src = getFileInfo(fileId)
    }
    setImg(src)
  }

  // 删除图片按钮
  const deleteImag = () => {
    delImage({
      fileType: type,
      studentId,
      unionId
    }).then(() => {
      setFileId('')
      setImg(defaultImg)
      itemImgChange(type, '')
    })
  }

  // 查看图片按钮
  const imgZoom = () => {
    setIsZoomed(true)
  }

  // 构造背景
  const cameraList = (srcImg, altText) => {
    const _zoom = {
      isZoomed: isZoomed,
      onZoomChange: handleZoomChange,
      overlayBgColorStart: 'rgba(0, 0, 0, 0)',
      overlayBgColorEnd: 'rgba(0, 0, 0, 0.5)',
    }

    return img !== null ?
      <ControlledZoom {..._zoom}><img className="center-img" src={srcImg} key={srcImg}
        alt={altText} /></ControlledZoom> : null
  }

  // 选择拍照还是签字的图片, 不是拍照就是签字, 当被填充了真实文件时，不可见
  const renderFlag = () => {
    return fileId ? null : <div className="camera-flag" />
  }

  // 提交图片，接口和数据格式不统一，粗口 身份证明正反面、原驾驶证、暂住证、体检表 调算法
  const commitImg = (operImg) => {
    const formData = new FormData()
    const usuals = [EnumFileType.现场照,EnumFileType.其它,EnumFileType.其它2,EnumFileType.其它3,EnumFileType.其它4,EnumFileType.其它5]
    if (usuals.includes(type)) { // TODO: 让后端输出了fileId, 记得回归
      const blob = operImg
      formData.append('trackdata', blob, new Date().getTime() + (blob.type.includes('jp') ? '.jpeg' : '.png'));
      formData.append('studentId', studentId);
      formData.append('fileType', type.toString());
      formData.append('unionId', unionId);
    }
    commitFile(submitUrl, formData).then(res => {
      console.log(res)
      Toast.success(`上传${title}成功`, 2, () => {
        loadImg(res.data)
        setFileId(res.data)
        itemImgChange(type, res.data)
        dlgPre.current && dlgPre.current.close()
      })
    })
  }

  const inputFileChange = (file: File, stepArr) => {
    if (!file) {
      Toast.fail('图片文件不能为空！')
      return
    }

    dlgPre.current = openDlg({
      type,
      file: file,
      // n次拍摄时显示的标题, 子组件依赖此判断步骤次数
      steps: stepArr,
      fileIndex: 0,  // 图片索引，多次拍摄时，确定更新的位置, 0 开始
      submitUrl: submitUrl,  // 上传地址
      // submitBefore?: () => {},
      // submitAfter?: () => {},
      onSave: async (imgList?: File[]) => { // 拍摄完成
        console.log('save image', imgList)
        const resImg = await compressImgToBlob(imgList[0])
        console.log('compress image', resImg)
        commitImg(resImg)
      }
    })
  }

  const renderCamera = () => {
    if (fileId) {
      return null
    }
    const cameraProps = {
      className: '',
      changeCallback: (file) => inputFileChange(file, steps),
    }
    return <Camera {...cameraProps} />
  }

  const openDlg = (params) => {
    return Dialog(<PreviewDlg {...params} />)
  }

  // 构造处理区域, 按钮组等
  const renderOperArea = () => {
    const operBtns = [
      <a key="zoom" className="oper-btn oper-zoom" onClick={() => imgZoom()}><img className="oper-img" src={ZoomSVG}
        alt="放大" /><span
          className="oper-txt">放大</span></a>,
      <a key="del" className="oper-btn oper-del" onClick={() => deleteImag()}><img className="oper-img" src={DelSVG}
        alt="删除" /><span
          className="oper-txt">删除</span></a>
    ]
    if (fileId) {
      return operBtns
    } else {
      return null
    }
  }

  return (
    <>
      <div className="imgload-bg">
        <div className="flex-center">
          <div className="block">
            {cameraList(img, text)}
            {renderFlag()}
          </div>
          {!fileId ? <span className="bg-txt">{text}</span> : null}
        </div>
        {renderCamera()}
      </div>
      <div className="oper-area">
        {renderOperArea()}
      </div>
    </>
  )
}

export default UsualCamera
