import React, { useState, useEffect } from 'react';

import { Tabs } from 'antd-mobile';
import { getStudentInfo } from '@/service/open'
import PictureDetail from '../../uploadPhoto/pictureDetail'
// import BasicForm from './basicForm'
import './index.css';
import qs from 'qs'

const Index = (props) => {

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { studentId = '', mode } = query || {};

  const [fileList, setFileList] = useState([])

  const tabs = [
    { title: '身份信息', sub: '1' },
    { title: '登记信息', sub: '2' },
    { title: '影像化资料', sub: '3' },
  ];

  // 将信息传递给子组件
  const [detailInfo, setDetailInfo] = useState<any>({})

  // 根据id查询当前学员的所有信息
  useEffect(() => {
    if (studentId) {
      getStudentInfo({ studentId }).then(res => {
        const getInfo = res.data;
        console.log(res);
        if (getInfo) {
          const inifInfo = {
            ...getInfo
          }
          setDetailInfo({ ...inifInfo })
          setFileList([...getInfo.fileList])
        }
      })
    }
  }, [])

  const renderUploadData = () => { // 新增和修改是一个流程，查看的数据来源和结构都发生了变化
    const params = {
      mode,
      fileList
    }
    return <PictureDetail {...params} />
  }
  const info = detailInfo;

  const source = { A: '本地', B: '外地', C: '本省外地' };
  return (
    <div className="ax-tab-wrapper ipx">
      <Tabs tabs={tabs}
        initialPage={0}
        renderTab={tab => <span className="tab-bars-height">{tab.title}</span>}>
        <div className="tabs-content">
          <ul className="detail-box">
            <li><span>姓名</span><span>{info.name}</span></li>
            <li><span>性别</span><span>{info.sex === 0 ? '男' : '女'}</span></li>
            <li><span>出生日期</span><span>{info.birthday}</span></li>
            <li className="hr"></li>
            <li><span>证件类型</span><span>{info.typeId}</span></li>
            <li><span>证件号码</span><span>{info.idnum}</span></li>
            <li><span>国籍</span><span>{info.country}</span></li>
            <li><span>证件有效期始</span><span>{info.idnumStart}</span></li>
            <li><span>证件有效期止</span><span>{info.idnumEnd}</span></li>
            <li><span>学员来源</span><span>{source[info.source]}</span></li>
            <li><span>登记住所行政区划</span><span>{info.registeredRegion}</span></li>
            <li><span>登记住所详细地址</span><span>{info.registeredAddress}</span></li>
          </ul>
        </div>
        <div className="tabs-content">
          <ul className="detail-box">
            <li><span>手机号码</span><span>{info.mobile}</span></li>
            <li><span>电子邮箱</span><span>{info.email}</span></li>
            <li><span>学习类别</span><span>{info.learningType === 0 ? '初次申领' : info.learningType === '1' ? '增驾' : ''}</span></li>
            <li><span>所学车型</span><span>{info.carId}</span></li>
            <li className="hr"></li>
            <li><span>联系住所行政区划</span><span>{info.contactRegion}</span></li>
            <li><span>登记住所详细地址</span><span>{info.contactAddress}</span></li>
            <li><span>邮政编码</span><span>{info.zipCode}</span></li>
            <li><span>暂住证编号</span><span>{info.residenceNumber}</span></li>
          </ul>
        </div>
        <div className="tabs-content" style={{ height: '100%' }}>
          <div className="relative flex-item flex" style={{ height: '100%' }}>
            {renderUploadData()}
          </div>
        </div>
      </Tabs>
    </div>
  )
}
export default Index;

