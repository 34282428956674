import React, { useMemo } from 'react'

import UsualCamera from './center/UsualCamera'
import UsualSign from './center/UsualSign'
import CertCamera from './center/CertCamera'
import NoticeSign from "./center/NoticeSign";
// import OperationCameraByGo from './center/OperationCameraByGoServer'
import OperationCameraByGoServerNew from './center/OperationCameraByGoServerNew'
import { SignTypeList, EnumFileType } from '../data'
import BasicItemLayout from '../layout/BasicItemLayout'

import './index.css'


// 只简单的判断了不是拍照就是签字
const validIsSignType = (signType: number) => {
  let res = false
  for (const val of SignTypeList) {
    if (val === signType) {
      res = true
      break;
    }
  }
  return res;
}


// tabs 中的 content 构造
// 分成两块，1. 要求文本 2. 中间功能区
const UploadItem: React.FC<Picture.UploadFile & { unionId?: string, idnum: string; carId: string; } & { centerImgRef: Record<string, string> }> = (props): JSX.Element => {
  const { type, centerImgRef } = props

  function itemImgChange(type, imgId) {
    centerImgRef[type + ''] = imgId
    console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzz', centerImgRef)
    // 不删，置空，防止删除了图片会被原有的fileList覆盖，让未刷新前都是被删除状态
    // else {
    //   const obj = centerImgRef.current;
    //   delete obj[type+'']
    //   centerImgRef.current = {
    //     ...centerImgRef.current,
    //   }
    // }

  }

  // 组件构造
  const renderCenter = useMemo(() => {
    const isSign = validIsSignType(type)
    const _usualProps = {
      ...props,
      itemImgChange,
    }
    if (centerImgRef[type + ''] != undefined) { // 覆盖
      _usualProps.fileId = centerImgRef[type + '']
    }
    if (isSign) {
      if (type === EnumFileType.办理申请大中型客货车驾驶证业务告知书 || type === EnumFileType.申告确认书) {
        return <NoticeSign {..._usualProps} />
      } else { // 通用签字多, 一个合并签字功能
        return <UsualSign {..._usualProps} />
      }
    } else {
      const usuals = [EnumFileType.现场照,EnumFileType.其它,EnumFileType.其它2,EnumFileType.其它3,EnumFileType.其它4,EnumFileType.其它5]
      if (type === EnumFileType.证件照) {
        return <CertCamera {..._usualProps} />
      } else if (usuals.includes(type)) {
        return <UsualCamera {..._usualProps} />
      } else {
        // return <OperationCameraByGo {...props} />
        return <OperationCameraByGoServerNew {..._usualProps} />
      }
    }
  }, [])

  return (
    <div className="flex-item imgload-container flex-center" style={{ minHeight: type === EnumFileType.证件照 ? 'inherit' : '' }}>
      {renderCenter}
    </div>
  )
}

export default BasicItemLayout(UploadItem)
