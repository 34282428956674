import { getRegionBySource as getRegionBySource2 } from '@/service/open'
import { getRegionBySource } from '@/service/erp/system'
/**
 * 通过身份证号码获取身份证信息
 */
const REGEX_IDNUMBER = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
// 手机号码验证
const REGEX_MOBILE = /^1[3456789]\d{9}$/;
// 邮箱验证
const REGEX_EMAIL = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
// 身份证号码的处理
function getInfoByIdNumber(idnum) {
  if (!REGEX_IDNUMBER.test(idnum)) {
    return false;
  }
  const year = idnum.substring(6, 10);
  const month = idnum.substring(10, 12);
  const day = idnum.substring(12, 14);
  return {
    year,
    month,
    day,
    birthday: `${year}-${month}-${day}`,
    sex: +idnum.substring(16, 17) % 2 == 0 ? 1 : 0
  };
}

// 通过区域递归获取邮编
const findZipCode = (arr, number) => {
  let code
  const findCode = (arr, number) => {
    arr.forEach(element => {
      if (element.children != null) {
        findCode(element.children, number)
      } else {
        if (element.value == number) {
          code = element.zipCode
        }
      }
    });
  }
  findCode(arr, number)
  return code
}

// 日期格式化
function formatDate(date, fmt) {
  if (typeof date === 'string') {
    if (/Z$/i.exec(date)) {
      date = new Date(new Date(date).getTime());
    } else {
      date = new Date(date.replace(/-/g, '/'));
    }
  }
  if (date === undefined || date === null) {
    return '--';
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  const week = {
    0: '/u65e5',
    1: '/u4e00',
    2: '/u4e8c',
    3: '/u4e09',
    4: '/u56db',
    5: '/u4e94',
    6: '/u516d',
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
    );
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      // eslint-disable-next-line no-nested-ternary
      (RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? '/u661f/u671f'
          : '/u5468'
        : '') + week[`${date.getDay()}`]
    );
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  }
  return fmt;
}

// 判断年龄
function getAgeByBirthday(strBirthday) {
  var returnAge,
    strBirthdayArr = strBirthday.split('-'),
    birthYear = strBirthdayArr[0],
    birthMonth = strBirthdayArr[1],
    birthDay = strBirthdayArr[2],
    d = new Date(),
    nowYear = d.getFullYear(),
    nowMonth = d.getMonth() + 1,
    nowDay = d.getDate();
  if (nowYear == birthYear) {
    returnAge = 0;
  } else {
    var ageDiff = nowYear - birthYear;
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay;
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth;
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1;
    }
  }
  return returnAge;
}

// 处理三级联动数组
function getSource(source, orgRegion = '') {
  if (orgRegion) {
    return getRegionBySource2({
      source,
      orgRegion,
    }).then((res) => {
      if (res.code == 'success') {
        return JSON.parse(JSON.stringify(res.data).replace(/id/g, "value").replace(/name/g, "label").replace(/regionList/g, "children"));
      } else {
        return
      }
    }).catch(() => {
      return null
    })
  } else {
    return getRegionBySource({
      source
    }).then((res) => {
      if (res.code == 'success') {
        return JSON.parse(JSON.stringify(res.data).replace(/id/g, "value").replace(/name/g, "label").replace(/regionList/g, "children"));
      } else {
        return
      }
    }).catch(() => {
      return null
    })
  }
}

export default {
  getInfoByIdNumber,
  getAgeByBirthday,
  findZipCode,
  getSource,
  REGEX_MOBILE,
  REGEX_EMAIL,
}

export {
  formatDate,
}
