import React from 'react'

function Camera(props: Picture.cameraProps): JSX.Element {
  const { className, changeCallback } = props

  const fileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileList = e.target.files
    const file = fileList?.length ? fileList[0] : null
    if (file) {
      changeCallback && changeCallback(file)
      // 算法端要File类型, string 过去转换
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = function () {
      //   console.log(reader.result)
      //   // 触发弹窗显示，传递file，传递图片数组索引作为更新依据
      //   changeCallback && changeCallback(reader.result as string)
      // };
    }
  }

  return <input className={`z-file ${className}`} capture="camera" type="file" accept="image/*" onChange={fileChange} />
}


export default Camera
