import { List, Toast, DatePicker,Picker,TextareaItem,Flex } from 'antd-mobile';
import style from './index.module.css';
import { useState } from 'react'
import LiInputItem from '@/components/inputItem/index'
import './index.less'

const LiCreateForm = (props) => {
  let getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo')) || {};
  const [indexNumber,setIndexNumber] = useState(getIdentutyInfo.sex || 0)
  const { fromTable,Props } = props
  // 从父级传来的Props
  const { getFieldProps, getFieldError } = Props.form
  // 必填 带星号
  const requiredSpan = (name)=>{
    return (
      <span className='from-label'><span className={style['red']}>*</span>{name}</span>
    )
  }
  // 非必填
  const span = (name)=>{
    return (
      <span className='from-label'>{name}</span>
    )
  }

 // input 输入
  const input = (item,index,Props) =>{
    return <LiInputItem Props={Props} item={item} key={index} />
  }

  // radio 单选
  const radio = (item,index) =>{
    return(
      <List key={index} className={style['list']} {...getFieldProps(item.valueName,{initialValue:indexNumber})}>
        <div className={style['flex-box-radio']}>
          <div className={style['sex-name']}>{item.required?requiredSpan(item.textName):span(item.textName)}</div>
          <div className={style['right-radio']}>
            <Flex justify="end">
              {item.select.map((itemChild,indexChild)=>{
                return <span key={indexChild}><input className={style['radio']} checked={indexNumber==indexChild?true:false} onChange={()=>{indexNumber==1?setIndexNumber(0):setIndexNumber(1)}} type="radio" name="sex" value={itemChild.value}/>{itemChild.label}</span>
              })}
            </Flex>
          </div>
        </div>
      </List>
    )
  }
  // 下拉选择
  const select = (item,index) =>{
    return(
      <List className={[style['select'],item.textClass]} key={index}>
        <Picker
          {...getFieldProps(item.valueName,{
            initialValue:item.initVal
          })}
          data={item.select}
          disabled={item.disabled}
          cols={item.cols || 1}
          onOk={item.ok?(val)=>{
            item.ok(val,Props,item.select)?Toast.info(item.ok(val,Props,item.select), 2) :null;
          } :null}
        >
          <List.Item arrow="horizontal">{item.required?requiredSpan(item.textName):span(item.textName)}</List.Item>
        </Picker>
      </List>
    )
  }
  // 时间选择
  const timeSelect = (item,index) =>{
    return(
      <List className={style['time-icon']} key={index}>
        <DatePicker
            {...getFieldProps(item.valueName)}
            disabled={item.disabled}
            mode="date"
            minDate={new Date(1900, 0, 1, 0, 0, 0)}
            maxDate={new Date(2099, 11, 31, 0, 0, 0)}
            title="日期选择"
            >
            <List.Item arrow="horizontal">{item.required?requiredSpan(item.textName):span(item.textName)}</List.Item>
        </DatePicker>
      </List>
    )
  }
  // 多行输入
  const textarea = (item,index) =>{
    return(
      <List key={index} className={item.textareaClass}>
        <div className='text-area'>{item.required?requiredSpan(item.textName):span(item.textName)}</div>
        <TextareaItem
          {...getFieldProps(item.valueName,{
            rules: [
              { validator:item.verify},
            ],
            initialValue:''
          })}
          labelNumber={9}
          rows={3}
          maxLength={50}
          editable={!item.disabled}
          error={!!getFieldError(item.valueName)}
          onErrorClick={() => {
            Toast.fail(getFieldError(item.valueName), 1);
          }}
          clear
          // title={<div className={style['textarea']}>{item.required?requiredSpan(item.textName):span(item.textName)}</div>}
          placeholder={item.placeholder}
          />
      </List>
    )
  }
  // 判断生成表单
  const fromElement = (dataTable)=> {
    return dataTable.map((item,index)=>{
      if (item.type == 'input') {
        return input(item,index,Props)
      } else if (item.type == 'radio') {
        return radio(item,index)
      } else if (item.type == 'select') {
        return select(item,index)
      } else if (item.type == 'timeSelect') {
        return timeSelect(item,index)
      } else if (item.type == 'textarea') {
        return textarea(item,index)
      }
    })
  }

  return(
    <div className={style['identity-info']}>{fromElement(fromTable)}</div>
  )
}

export default LiCreateForm;
