import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd-mobile";

function WithPrettyCheckbox(Component) {
  return (props) => {
    // 是否使用美化, 如果
    const [showPretty, setShowPretty] = useState(false)
    // 剩余制证照次数
    const [certCount, setCertCount] = useState(0)

    const certCountChange = (num: number) => {
      setCertCount(num)
    }

    const _props = useMemo(() => {
      return {
        showPretty,
        certCountChange,
        ...props
      }
    }, [showPretty])

    return (
      <>
        <Component {..._props} />
        {/* <div className="oper-extra">
          <Checkbox className="checkbox-pretty" checked={showPretty} disabled={isUseService} onChange={(e) => setShowPretty(e.target.checked)} />
          <span className="oper-extra-txt">{`证件照专业服务（照片抠图、人像美化、打 印）剩余次数：${certCount}次`}</span>
        </div> */}
      </>
    )
  }
}

export default WithPrettyCheckbox
