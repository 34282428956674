import request from '../../utils/request'
import { EnumFileType } from '../../page/uploadPhoto/data'


// 获取机动车驾驶证申请表
export function driverLicense(params: Student.StudentId): Promise<API.asyncEntity<string>> {
  return request.get('/api/student/downloadImage?fileType=' + EnumFileType.驾驶证申请表, { params })
}

// 获取告知书
export function notification(): Promise<API.asyncEntity<string>> {
  return request.get('/api/student/downloadImage?fileType=' + EnumFileType.办理申请大中型客货车驾驶证业务告知书)
}

// 获取注册申请表
export function registered(params: Student.StudentId): Promise<API.asyncEntity<string>> {
  return request.get('/api/student/downloadImage?fileType=' + EnumFileType.注册申请表, { params })
}

// 上传签字信息
export function uploadSignature(data: API.SignProps): Promise<API.asyncEntity<string>> {
  return request.post('/api/student/uploadSignature', { data })
}

export function getImageFile(url: string): Promise<API.asyncEntity<string>> {
  return request.get(url)
}
