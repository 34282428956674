
import request from '@/utils/request'

// 检查学员是否报名
export function checkStudent(unionId: string): Promise<API.asyncEntity<boolean>> {
    return request.get(`/api/student/checkRegistered?unionId=${unionId}`)
}

// 获取国籍
export function getCountry(): Promise<API.asyncEntity<School.Country[]>> {
    return request.get('/api/system/countryList')
}
// 查询已开通自考的区域
export function getRegisterList(params: { id: number }): Promise<API.asyncEntity<School.Source>> {
    return request.get('/api/system/supportedRegisterRegion', { params })
}


// 获取电子制证照
export function getPhoto(unionId: string): Promise<API.asyncEntity<string>> {
    return request.get('/api/student/idPhotoInfo?unionId=' + unionId)
}

// 获取区域
export function getRegionBySource(data: { source: string, orgRegion: string }): Promise<API.asyncEntity<School.Source>> {
    return request.get(`/api/system/regionList?source=${data.source}&orgRegion=${data.orgRegion}`)
}

// 上传电子制证照
export function saveElectronic(data: any): Promise<API.asyncEntity<any>> {
    return request.post(`/api/student/makeIdPhoto?source=supplement`, { data })
}
// 获取验证码
export function getYzmCode(): Promise<API.asyncEntity<any>> {
    return request.get(`/api/system/getCaptchaCode`)
}
// 获取制证照价格
export function getPhotoPrice(regionId: string): Promise<API.asyncEntity<any>> {
    return request.get(`/api/system/idPhotoPrice?regionId=` + regionId)
}

// 获取驾校模板信息
export function getTemplateInfo(orgRegion: string): Promise<API.asyncEntity<School.Template>> {
    return request.get('/api/system/template?orgRegion=' + orgRegion)
}
// 保存或更新学员第一步(点击下一步就保存学员到数据库)
export function firstStep(data: Student.StudentId): Promise<API.asyncEntity<School.Source>> {
    return request.post('/api/student/saveBaseInfo', { data })
}
// 获取制证照ID, 制证照的Id不在firstStep的fileList返回，需单独查询
export function getCertificatePhotoId(data: Student.StudentId): Promise<API.asyncEntity<string>> {
    return request.get(`/api/student/idPhotoFileId?studentId=${data.studentId}&unionId=${data.unionId}`)
}

// 上传签字信息
export function uploadSign(data: API.SignProps): Promise<API.asyncEntity<string>> {
    return request.post('/api/student/uploadSignature', { data })
}
export function commitSpecialPic(data: {
    studentId: string;
    fileType: number;
    base64: string;
    unionId: string,
}): Promise<API.asyncEntity<string>> {
    return request.post(`/api/student/uploadImageBase64`, { data, timeout: 2 * 60 * 1000 })
}
// 删除图片
export function delImage(data: {
    unionId: string;
    fileType: number;
} & Student.StudentId): Promise<API.asyncEntity<null>> {
    return request.post(`/api/student/deleteImage?studentId=${data.studentId}&fileType=${data.fileType}&unionId=${data.unionId}`)
}
// 验证是否存在电子文件
export function checkFileExist(params: {
    fileType: number;
    studentId: string;
    cache: number;
    unionId: string;
}): Promise<API.asyncEntity<boolean>> {
    return request.get('/api/student/checkImageExist', { params })
}

// 同步证件照到签字头像
export function sycPassportPhotoToHeadPhoto(data: Student.StudentId): Promise<API.asyncEntity<null>> {
    return request.post(`/api/student/syncIdPhotoToHeadPhoto?studentId=${data.studentId}`)
}
// 保存学员
export function confirm(data: Student.StudentId): Promise<API.asyncEntity<Record<string, unknown>>> {
    return request.post(`/api/student/submit?studentId=${data.studentId}&unionId=${data.unionId}`)
}

// 删除证件照
export function reCertificatePhoto(data: Student.StudentId): Promise<API.asyncEntity<null>> {
    return request.post(`/api/student/deleteImage?studentId=${data.studentId}&unionId=${data.unionId}&fileType=15&source=idPhoto`)
}

// 获取图片路径
export function getImageFile(url: string): Promise<API.asyncEntity<string>> {
    return request.get(url)
}
/**
 * 提交证件照，返回值和其他接口不一致
 * @param url
 * @param data
 */
export function commitFileByCert(url: string, data: FormData): Promise<API.asyncEntity<string>> {
    return request.post(url, { data, timeout: 2 * 60 * 1000 })
}

// 查询已开通自考的区域
export function submitPhotoList(params: { id: number }): Promise<API.asyncEntity<School.Source>> {
    return request.get('/api/system/supportedIdPhotoRegion', { params })
}
// 查询已开通自考的区域
export function beautify(url: string, data: FormData): Promise<API.asyncEntity<School.Source>> {
    return request.post(url, { data, timeout: 2 * 60 * 1000 })
}


// 根据unionId查询学员信息列表
export function getStudentList(unionId: string): Promise<API.asyncEntity<School.Source>> {
    return request.get('/api/student/list?unionId=' + unionId)
}
// 查看详情
export function getStudentInfo(params: Student.StudentId): Promise<API.asyncEntity<Student.StudentInfo>> {
    return request.get('/api/student/details', { params })
}
// 学员基础详情
export function getStudentBaseInfo(params: Student.StudentId): Promise<API.asyncEntity<Student.StudentInfo>> {
    return request.get('/api/student/baseInfo', { params })
}
