import React, { useState, useEffect, useCallback } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Toast, Modal } from 'antd-mobile'

import Camera from '../../camera'
import PreviewDlg from '../../previewDlg'
import { getEditStudentImgServerUrl, getFileInfo } from '../../util/urlCreator'
import styles from './CertCamera.module.less';
import { navigateTo } from '@/utils/wxApi'


import { getCertificatePhotoId, reCertificatePhoto, getYzmCode, getPhotoPrice, beautify } from '@/service/open';


import Dialog from '@/components/dialog'
import WithPrettyCheckbox from '../../layout/WithPrettyCheckbox'

import DelSVG from '@/static/images/del.svg'
import ZoomSVG from '@/static/images/zoom.svg'
import jszbg from '@/static/images/jszbg.png';
import './center.css'


/**
 * 证件照拍照(寸照)，因为按照PC端进入电子照页面就刷新证件照，否则后端不会将此数据加入缓存，无法判断是否用到头像，签字会报错，所以单独加载渲染
 * 由父组件查询并传递
 * 整体有四层 1. 灰色背景  2. 触发开启相机的透明遮罩 3. 背景取景框  4. 根据拍照次数和类型决定的背景图  4. 相机图片
 */
function CertCamera(props: Picture.UploadFile & Picture.requirementProps & { unionId?: string, showPretty: boolean, certCountChange: (num: number) => void }): JSX.Element {
  // 对于制证照有独立接口提供，不需要父组件持有其文件id itemImgChange
  let getIdentutyInfo: any = {}
  try {
    getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo')) || {};
  } catch (error) { console.log(error) }
  const {
    defaultImg, text, type, steps, submitUrl,
    studentId, title, showPretty,
    unionId
  } = props


  const { texts, exImg } = props

  const [img, setImg] = useState(defaultImg)
  const [fileId, setFileId] = useState('')
  // 是否缩放
  const [isZoomed, setIsZoomed] = useState(false)

  const [visible, setVisible] = useState(false);

  const [yzmShow, setYzmShow] = useState<boolean>(false);
  const [yzmImg, setYzmImg] = useState<string>('');
  const [captcha, setCaptcha] = useState<string>('');
  const [captchaKey, setCaptchaKey] = useState<string>('');

  const [payShow, setPayShow] = useState<boolean>(false);
  const [submitPrice, setSubmitPrice] = useState<string>('');
  const [payData, setPayData] = useState<any>({});


  const [imgFile, setImgFile] = useState<any>({});

  const [domDlg, setDomDlg] = useState(null)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  useEffect(() => {
    profileRefresh()
  }, [fileId])

  const profileRefresh = () => {
    getCertificatePhotoId({ studentId, unionId }).then(res => {
      if (res.data) {
        setFileId(res.data)
        loadImg(res.data)
      }
    })
  }

  // 加载图片
  const loadImg = (fileId?: string) => {
    let src: string
    if (!fileId) {
      src = getEditStudentImgServerUrl(type);
    } else {
      src = getFileInfo(fileId)
    }
    setImg(src)
  }

  // 删除图片按钮事件
  const deleteImage = () => {
    reCertificatePhoto({ studentId, unionId }).then(() => {
      setFileId('')
      setImg(defaultImg)
    })
  }

  // 查看图片按钮
  const imgZoom = () => {
    setIsZoomed(true)
  }

  // 构造背景
  const cameraList = () => {
    const _zoom = {
      isZoomed: isZoomed,
      onZoomChange: handleZoomChange,
      overlayBgColorStart: 'rgba(0, 0, 0, 0)',
      overlayBgColorEnd: 'rgba(0, 0, 0, 0.5)',
    }

    return img !== null ?
      <ControlledZoom {..._zoom}><img className="center-img" src={img} key={img} alt={text} /></ControlledZoom> : null
  }

  // 选择拍照还是签字的图片, 不是拍照就是签字, 当被填充了真实文件时，不可见
  const renderFlag = () => {
    return fileId ? null : <div className="camera-flag" />
  }

  // 提交图片，接口和数据格式不统一, 身份证明正反面、原驾驶证、暂住证、体检表 调算法
  const commitImg = (imgList, captchaKey = '', captcha = '') => {
    const formData = new FormData()
    const blob = imgList[0]
    formData.append('file', blob, new Date().getTime() + (blob.type.includes('jp') ? '.jpeg' : '.png'));
    formData.append('studentId', studentId);
    formData.append('unionId', unionId);
    formData.append('orgRegion', getIdentutyInfo.orgRegion);
    if (captchaKey && captcha) {
      formData.append('captchaKey', captchaKey);
      formData.append('captcha', captcha);
    }
    beautify(submitUrl, formData).then((res: any) => {
      const data = res.data || {};
      if (res.code === '999') {
        setYzmShow(true);
        getYzm();
      } else if (data.fileId) {
        setYzmShow(false);
        Toast.success(`上传${title}成功`, 2, () => {
          domDlg && domDlg.close();
          const $dom = document.getElementById('pop_certCamera');
          if ($dom && $dom.parentNode) {
            $dom.parentNode.removeChild($dom);
          }
          loadImg(data.fileId)
          setFileId(data.fileId)
          setPayData(data);
          setCaptcha('');
          setVisible(false);
          if (data.photoSwitch == 0 || data.electronicPhoto === 'used') {
            setPayShow(false)
          } else {
            setPayShow(true);
          }
        })
      }

    })

  }
  const getYzm = () => {
    getYzmCode().then(yzm => {
      setYzmImg(yzm.data.base64Img);
      setCaptchaKey(yzm.data.captchaKey);
    })
  }
  const renderCamera = () => {
    if (fileId) {
      return null
    }
    const cameraProps = {
      className: '',
      changeCallback: function (filestr: File) {
        if (!filestr) {
          Toast.fail('图片文件不能为空！')
          return
        }
        setCaptcha('');
        setCaptchaKey('');
        setVisible(false);
        const dlgPre = openDlg({
          type,
          file: filestr,
          // n次拍摄时显示的标题, 子组件依赖此判断步骤次数
          steps: steps,
          fileIndex: 0,  // 图片索引，多次拍摄时，确定更新的位置, 0 开始
          submitUrl: submitUrl,  // 上传地址
          // submitBefore?: () => {},
          // submitAfter?: () => {},
          onSave: (imgList?: File[]) => {
            setImgFile(imgList);
            commitImg(imgList)
          }
        })
        setDomDlg(dlgPre);
      }
    }
    return <Camera {...cameraProps} />
  }

  const openDlg = (params) => {
    return Dialog(<PreviewDlg {...params} />)
  }

  // 构造处理区域, 按钮组等
  const renderOperArea = () => {
    const operBtns = [
      <a key="zoom" className="oper-btn oper-zoom" onClick={() => imgZoom()}><img className="oper-img" src={ZoomSVG} alt="放大" /><span className="oper-txt">放大</span></a>,
      <a key="del" className="oper-btn oper-del" onClick={() => deleteImage()}><img className="oper-img" src={DelSVG} alt="删除" /><span className="oper-txt">删除</span></a>
    ]
    if (fileId) {
      return operBtns
    } else {
      return null
    }
  }
  const goToShot = () => {
    const citys = getIdentutyInfo.orgRegion.split(':');
    let valCity = '';
    if (citys && citys.length > 2) {
      valCity = citys[1];
    }
    getPhotoPrice(valCity).then(res => {
      const data = res.data;
      setSubmitPrice(data.registerPrice)
    });
    setVisible(true)
  }
  const modalClose = () => {
    setVisible(false)
  }
  const onYzmSave = () => {
    if (!captcha) {
      Modal.alert('提示', '请输入验证码');
      return false
    }
    commitImg(imgFile, captchaKey, captcha);
  }
  const yzmHide = () => {
    setYzmShow(false)
  }

  const photoSubmit = () => {
    const citys = getIdentutyInfo.orgRegion.split(':');
    let valCity = '';
    if (citys && citys.length > 2) {
      valCity = citys[1];
    }
    if (payData.electronicPhoto === 'used') {
      setPayShow(false)
    } else {
      setPayShow(false)
      navigateTo(`/ucenter/pages/pay/index?fileId=${payData.fileId}&wsyFileId=${payData.wsyFileId}&regionId=${valCity}&source=independent&studentId=${payData.studentId}`);
    }
  }
  const renderList = (txtList: string[]) => txtList.map((txt: string, index: number) => {
    return <span className='txt-item' key={index}>{txt}</span>
  })
  return (
    <>
      {
        !fileId && <div>
          <div className='requirements zjz-box'>
            <img className="exImg" src={exImg} alt="" />
            <h4>公安部对驾驶证照片的最新要求：</h4>
            {renderList(texts)}
            <p style={{ color: '#EE483E' }}>注意：不符合以上要求将会导致照片审核不通过。</p>
          </div>
          <div className={styles.btns}>
            <button className={styles.ljpz} onClick={goToShot}>
              <i></i>立即拍照<span style={{ fontSize: '12px' }}>(电子证件照制作)</span>
            </button>
          </div>
        </div>
      }
      {
        fileId && cameraList()
      }
      <div className="oper-area">
        {renderOperArea()}
      </div>

      <Modal
        visible={visible}
        transparent
        onClose={modalClose}
        title={'电子证件照制作服务'}
      >
        <div style={{ textAlign: 'left', padding: '10px 0' }}>
          <p>· 立即拍摄，速度快</p>
          <p>· 照片自动切换为白底</p>
          <p>· 人像美颜，照片更加美观</p>
          <p>· 自动检测照片是否符合要求</p>
        </div>
        <div className={styles.btns} style={{ padding: 0 }}>
          <button>
            我知道了，去拍照
            {renderCamera()}
          </button>
        </div>
      </Modal>

      <Modal
        visible={yzmShow}
        transparent
        onClose={yzmHide}
      >
        <div className={styles['yzm-content']}>
          <h3>请输入验证码</h3>
          <img onClick={getYzm} src={yzmImg} alt="" />
          <input type="text" placeholder="请输入验证码" value={captcha} onChange={(e) => { setCaptcha(e.target.value) }} />
          <div className={styles['btns']}>
            <button onClick={onYzmSave}>确定</button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={payShow}
        onClose={() => setPayShow(false)}
      >
        <div className={styles['pay-box']}>
          <div>
            <img src={jszbg} alt="" />
          </div>
          <div className={styles['psyq']}>
            <h3>电子证件照用于驾驶证的照片打印，电子证件照服务包含如下优势：</h3>
            <ol>
              <li>· 可免去提交纸质照片，方便快捷</li>
              <li>· 自动处理为1寸标准白底照片</li>
              <li>· 人像美颜，照片更加美观</li>
              <li>· 自动检测照片是否符合要求</li>
            </ol>
          </div>
          <div className={styles['pssm']}>
            <div className={styles['tips']}>
              <span>￥{submitPrice}/次</span>
              <p>（购买后，重复拍摄不收费）</p>
            </div>
          </div>
          <div className={styles['btns']}>
            <button onClick={photoSubmit}>
              去支付，并提交照片
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default WithPrettyCheckbox(CertCamera)
