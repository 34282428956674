import React, { useState, useEffect, useRef, useReducer } from 'react'

import Camera from '../camera'
import type { btnProps } from '../../../components/FlexBtn';
import LiBtns from '../../../components/FlexBtn';
import ZCropper from "../../../components/ZCropper";
import { getPicPoints, getPicBegin, getPicEnd } from '@/service/file/index'
import { compressImg } from "../util/operImg";



const renderBtnText = (pretxt: string, txt: string): string => {
  if (txt) {
    return `${pretxt}并${txt}`
  }
  return `${pretxt}`
}

/**
 * 分为父组件触发和自更新
 * 父组件唤醒拍摄后获取到文件，触发此组件初始化
 * @param props
 * @returns
 */
function PreviewOperationDlg(props: Picture.previewProps): JSX.Element {
  // titles 描述了要操作的步骤，文本被合成到按钮上显示
  const { file, fileIndex, steps, type, studentId } = props
  const { onSave } = props
  // 父组件触发拍摄后，传递过来的照片文件, 记录n次拍摄
  // 本来记录图片是要传递回父组件并参与提交，但是。。。
  // 这里都是要用服务处理的图片，留存此参数作为预览回显，并不参与提交了
  const [imgList, setImgList] = useState([file])
  const [current, setCurrent] = useState(fileIndex) // 当前拍摄的步骤
  // const [currUrl, setCurrUrl] = useState('')  // 当前展示的图片url
  // const [prePoints, setPrePoints] = useState([]) // 接口返回的预置点

  const pointsRef = useRef<Picture.point[]>() // 只接收cropper回调
  // 这里没有缩放比，点都应该在之前被处理好
  const pointChange = (points) => {
    pointsRef.current = points
  }

  const [cropperProps, dispatch] = useReducer((state, action) => {
    if (action.type === 'url') {
      state.image = action.payload
      state.points = []
      return { ...state }
    } else if (action.type === 'points') {
      return { ...state, points: action.payload }
    }
  }, {
    points: [],
    image: '',
    pointChange: pointChange,
  })

  const goInterfaceId = (index) => {
    let goInterfaceFlag = 'A'
    if (steps.length === 2) {
      goInterfaceFlag = 'S'
    } else if (steps.length === 3) {
      if (index > 0) {
        goInterfaceFlag = 'B'
      }
    }
    return `${studentId}-${type}-${goInterfaceFlag}`
  }

  function renderCameraBtn({ name, callback, className }) {
    return (
      <div className={className}>
        <Camera className="" changeCallback={callback} />
        <span>{name}</span>
      </div>
    )
  }

  useEffect(() => {
    const img = imgList[current]
    // 压缩
    img && compressImg(img).then(imgBase64 => {
      // 裁剪是在预览界面，而且父组件和预览都有选取图片的操作，最终展示都在这里
      // 预览是共用组件，需要另外的标识来指明用裁剪组件,
      pointsRef.current = null
      getPicPoints({
        fileData: imgBase64.split(',')[1], // 截取 data:image/png;base64,,
        id: goInterfaceId(current)
      }).then(getPoints => {
        const points = getPoints.data || []
        pointsRef.current = points
        // setPrePoints(points)
        dispatch({
          type: 'points',
          payload: points
        })
      })
    })
  }, [imgList])

  useEffect(() => {
    if (imgList[current]) {
      // if (currUrl) {
      if (cropperProps.image) {
        URL.revokeObjectURL(cropperProps.image)
      }
      // setCurrUrl(getUrl(imgList[current]))
      dispatch({
        type: 'url',
        payload: getUrl(imgList[current])
      })
    }
  }, [imgList])

  // 重新拍摄事件，文件嵌入current作为索引的图片数组中
  const cameraChange = (img: File) => {
    const tempArr = [].concat(imgList)
    tempArr[current] = img
    setImgList([...tempArr])
  }

  // 步骤驱动
  const stepClickHandler = async (img: File) => {
    const step = current + 1
    if (step === steps.length) { // 完成了，该提交
      const res = await getPicEnd({
        id: goInterfaceId(step),
        points: pointsRef.current
      })
      onSave && onSave(res.data)
    } else {
      await getPicBegin({
        id: goInterfaceId(current),
        points: pointsRef.current
      })

      // 设置步进器
      // setPrePoints([])
      setCurrent(step)
      const tempArr = [].concat(imgList)
      tempArr[step] = img

      // 设置文件列表
      setImgList([...tempArr])
    }
  }

  // 最终都会被处理为单张图片
  // 只获取图片传给父组件，提交由父组件完成
  const saveCamera = async function () {
    // TODO: 算法端接口调用 imgList
    const res = await getPicEnd({
      id: goInterfaceId(current + 1),
      points: pointsRef.current
    })
    onSave && onSave(res.data)
  }

  // 两个按钮，左为重新拍摄，右的文本根据步骤改变
  const renderBtns = () => {
    const btns: btnProps[] = []

    const btn = {
      name: '重新拍摄',
      className: 'btn-last btn-camera',
      callback: cameraChange,
    }

    btns.push(renderCameraBtn(btn))

    // 当还存在拍摄步骤时，不会保存，按钮实际执行的还是拍摄事件
    const lastIndex = steps.length - 1;
    if (current + 1 === lastIndex) { // 最后一步时是纯按钮，不被高阶组装
      btns.push({
        name: '保存',
        className: '',
        onClick: saveCamera
      });
    } else {
      const txt = renderBtnText('保存', steps[current + 1])
      btn.callback = stepClickHandler
      btn.name = txt
      btn.className = 'btn-next'
      btns.push(renderCameraBtn(btn))
    }

    const props = {
      arr: btns
    }

    return <LiBtns {...props} />
  }

  const getUrl = (img) => {
    console.log('set current img', img)
    return URL.createObjectURL(img)
  }

  const renderCropper = () => { // 从只展示图片，变为对go服务的图片裁剪等流程处理
    console.log(cropperProps)
    if (cropperProps.points.length) {
      // const cropperProps = {
      //   points: prePoints,
      //   image: currUrl,
      //   pointChange,
      // }
      return <ZCropper {...cropperProps} />
    }
    return null
  }

  return (
    <>
      <div className="pre-title">{steps[current]}</div>
      {renderCropper()}
      <div className="btns ipx">
        {renderBtns()}
      </div>
    </>
  )
}

export default PreviewOperationDlg
