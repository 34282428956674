import React from 'react';

import { Tabs } from '../../../components/tabs';
import type { PropsType, TabBarPropsType } from '../../../components/tabs'
import { sortTypeList } from '../data'
import UploadItem from './Item'

import styles from '../index.module.css';

export interface TabData {
  key?: string;
  title: React.ReactNode;
  /** for user's custom extends */
  [key: string]: unknown;
}

const PictureDetail: React.FC<{
  fileList: API.fileType[]
}> = (props) => {
  const {
    fileList
  } = props

  // 页面接收由后端返回的电子文档列表，和静态比较 (data.ts -> typeMap), 根据业务选择需要渲染的列表
  const renderTabs = () => {
    const tablist = [...fileList]

    return tablist.length ? sortTypeList(tablist) : []
  }

  const tabs = fileList ? renderTabs() : []

  const tabsProps = (): PropsType => {
    return {
      tabs: tabs,
      tabBarPosition: "left",
      swipeable: true,
      destroyInactiveTab: true,
      tabDirection: 'vertical',
      tabBarBackgroundColor: '#f0f0f0',
      renderTabBar: (params: TabBarPropsType) => <Tabs.DefaultTabBar {...params} page={8} />
    }
  }

  const renderContent = () => tabs.map((item) => {
    const _props = {
      fileId: item.fileId,
      requirement: item.requirement
    }
    return <UploadItem {..._props} key={item.fileId} />
  })

  return (
    <div className={styles.tabsMain}>
      <Tabs {...tabsProps()}>
        {renderContent()}
      </Tabs>
    </div>
  )
}
export default PictureDetail;
