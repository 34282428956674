import request from '@/utils/request'


// 获取驾校模板信息
export function getTemplateInfo():Promise<API.asyncEntity<School.Template>> {
  return request.get('/api/system/template')
}


// 获取国籍
export function getCountry():Promise<API.asyncEntity<School.Country[]>> {
  return request.get('/api/system/countryList')
}

// 获取区域
export function getRegionBySource(data: {source: string}):Promise<API.asyncEntity<School.Source>> {
  return request.get(`/api/system/regionList?source=${data.source}`)
}


// 获取短信验证码
export function getSendSmsCode(data: {mobile: string}):Promise<API.asyncEntity<string>> {
  return request.post('/api/system/sendSmsCode', {data})
}
