import React, {useEffect, useState} from "react";

import TimeSetting from './TimeSetting'
import {SchoolDropdown, SubjectDropdown} from "./DropdownCondition/index";
import PercentLine from './Charts/PercentLine'
import {passRateStatistics} from '@/service/chart'

import styles from './card.module.less'

function PassRateBox(props) {
  const [chartData, setChartData] = useState([])
  const [orgId, setOrgId] = useState(null)
  const [subject, setSubject] = useState(null)
  const [dateType, setDateType] = useState(7)

  useEffect(() => {
    requestData()
  }, [subject, orgId, dateType])

  const requestData = () => {
    passRateStatistics({
      orgId,
      subject,
      dateType,
    }).then(res => {
      if(res.data.length) {
        setChartData(res.data)
      }
    })
  }

  const selectedChangeHandler = (dateType) => {
    setDateType(dateType.id)
  }

  const timeSettingProps = {
    title: '数据粒度',
    items: [{key: 7, id: 7, name: '月'}, {key: 8, id: 8, name: '年'}],
    selectedChange: selectedChangeHandler
  }

  const dataKeys = ['date', 'percentage']

  const schoolSelectedChange = (value) => {
    setOrgId(value)
  }

  const subjectSelectedChange = (value) => {
    setSubject(value)
  }

  return (
    <div className={styles.card}>
      <div className={styles.title}>学员考试合格率</div>
      <div className={styles.condItem}>
        <TimeSetting {...timeSettingProps} />
      </div>
      <div className={styles.condItem}>
        <SchoolDropdown dropdownChange={schoolSelectedChange} />
      </div>
      <div className={styles.condItem}>
        <SubjectDropdown dropdownChange={subjectSelectedChange} />
      </div>

      <PercentLine data={chartData} dataKeys={dataKeys}  />
    </div>
  )

}

export default PassRateBox
