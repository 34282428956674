import sfz from '../../static/images/sfz.png'
import camera1 from '../../static/images/camera-1.png'
import camera2 from '../../static/images/camera-2.png'
import camera3 from '../../static/images/camera-3.png'
import jsz from '../../static/images/jsz.png'

import zjzExample from '../../static/images/example-zjz.png'
import xczExample from '../../static/images/example-xcz.png'
import czExample from '../../static/images/example-cz.png'

// 4,身份证明正反面;9,原驾驶证;15,证件照;2,现场照;
// 7,体检表;30,其它;8,指纹录入;6,驾驶证申请表;3,注册申请表;
export const EnumFileType = {
  '身份证电子照片': 1,
  '现场照': 2,
  '注册申请表': 3,
  '身份证明正反面': 4,
  '暂住证': 5,
  '驾驶证申请表': 6,
  '体检表': 7,
  '指纹录入': 8,
  '原驾驶证': 9,
  '体检照片': 10,
  '证件照': 15,
  '其它': 30,
  '其它2': 42,
  '其它3': 43,
  '其它4': 44,
  '其它5': 45,
  '办理申请大中型客货车驾驶证业务告知书': 31,
  '申告确认书': 34,
};

// 签字类型列表
export const SignTypeList = [3, 6, 31, 34]

// 通用
const usualTxts = ['四角完整', '高度均匀', '图片清晰']
// 现场照
const xczTxts = ['上半身照', '环境明亮', '图片清晰']
// 证件照
const zjzTxts = ['在白色的背景前拍照', '不带眼镜、露出耳朵和额头', '人像居中、双眼水平', '中性表情、肩膀等高']
// 签字
const signTxts = ['必须本人签字', '签字清晰完整']

// 类型映射
const typeMap = [
  {
    title: '身份证明', // 显示的标签名称
    type: EnumFileType.身份证明正反面,
    defaultImg: sfz,
    steps: ['拍摄身份证正面', '拍摄身份证反面', '保存'],
    submitUrl: '/api/student/uploadImageBase64',
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
      exImg: zjzExample,
      title: '证件拍摄示例',
    }
  },
  {
    title: '现场照',
    type: EnumFileType.现场照,
    defaultImg: camera3,
    steps: ['拍摄现场照', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄现场照',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: xczTxts,
      exImg: xczExample,
      title: '现场照拍摄示例',
    }
  },
  {
    title: '证件照片',
    type: EnumFileType.证件照,
    defaultImg: camera3,
    steps: ['拍摄寸照', '保存'],
    submitUrl: '/api/student/makeIdPhoto?source=school',
    fileId: '',
    text: '点击拍摄寸照',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: zjzTxts,
      exImg: czExample,
      title: '寸照拍摄示例',
    }
  },
  {
    title: '原驾驶证',
    type: EnumFileType.原驾驶证,
    defaultImg: jsz,
    steps: ['拍摄原驾驶证正面', '拍摄原驾驶证反面', '保存'],
    submitUrl: '/api/student/uploadImageBase64',
    fileId: '',
    text: '点击拍摄驾驶照',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '暂住证',
    type: EnumFileType.暂住证,
    defaultImg: camera2,
    steps: ['拍摄暂住证', '保存'],
    submitUrl: '/api/student/uploadImageBase64',
    fileId: '',
    text: '点击拍摄暂住证',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '体检表',
    type: EnumFileType.体检表,
    defaultImg: camera2,
    steps: ['拍摄体检表', '保存'],
    submitUrl: '/api/student/uploadImageBase64',
    text: '点击拍摄体检表',
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '申请表',
    type: EnumFileType.驾驶证申请表,
    defaultImg: camera1,
    submitUrl: '',
    text: '点击签字',
    tempFile: '/api/student/downloadImage?fileType=' + EnumFileType.驾驶证申请表,
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: signTxts,
    }
  },
  {
    title: '面签表',
    type: EnumFileType.注册申请表,
    defaultImg: camera1,
    submitUrl: '',
    text: '点击签字',
    tempFile: '/api/student/downloadImage?fileType=' + EnumFileType.注册申请表,
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: signTxts,
    }
  },
  {
    title: '大车告知书',
    type: EnumFileType.办理申请大中型客货车驾驶证业务告知书,
    defaultImg: camera1,
    submitUrl: '',
    text: '点击签字',
    tempFile: '/api/student/downloadImage?fileType=' + EnumFileType.办理申请大中型客货车驾驶证业务告知书,
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: signTxts,
    }
  },
  {
    title: '申告确认书',
    type: EnumFileType.申告确认书,
    defaultImg: camera1,
    submitUrl: '',
    text: '点击签字',
    tempFile: '/api/student/downloadImage?fileType=' + EnumFileType.申告确认书,
    fileId: '',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: signTxts,
    }
  },
  {
    title: '其他材料1',
    type: EnumFileType.其它,
    defaultImg: camera2,
    steps: ['拍摄其他材料', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄其他材料',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '其他材料2',
    type: EnumFileType.其它2,
    defaultImg: camera2,
    steps: ['拍摄其他材料', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄其他材料',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '其他材料3',
    type: EnumFileType.其它3,
    defaultImg: camera2,
    steps: ['拍摄其他材料', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄其他材料',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '其他材料4',
    type: EnumFileType.其它4,
    defaultImg: camera2,
    steps: ['拍摄其他材料', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄其他材料',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
  {
    title: '其他材料5',
    type: EnumFileType.其它5,
    defaultImg: camera2,
    steps: ['拍摄其他材料', '保存'],
    submitUrl: '/api/student/uploadImageFile',
    fileId: '',
    text: '点击拍摄其他材料',
    required: true, // 是否必填
    isComplete: false, // 是否完成
    requirement: { // 要求项
      texts: usualTxts,
    }
  },
]

// 输出排序后的电子照片列表
export function sortTypeList(params?: Array<{
  type: number;
  fileId?: string;
  status?: number;
}>, aimMap = typeMap): Array<Picture.initProps> {
  return [...aimMap.filter(item => {
    for(const para of params) {
      if(para.type === item.type) {
        item.required = para.status === 1 ? true : false
        item.fileId = para.fileId || ''
        return true
      }
    }
    return false
  })]
}
