import React, { useState, useEffect } from 'react';

import { Tabs } from 'antd-mobile';
import { getStudentInfo } from '@/service/student/index'
import PictureDetail  from '../uploadData/pictureDetail'
import BasicForm from './basicForm'
import './index.css';

const Index = (props) => {
    const { mode, studentId } = props.location.query
    const [fileList, setFileList] = useState([])
    const tabs = [
        { title: '基本信息', sub: '1' },
        { title: '影像化资料', sub: '2' },
    ];

    // 将信息传递给子组件
    const [detailInfo,setDetailInfo] = useState(null)

    // 根据id查询当前学员的所有信息
    useEffect(() => {
        if (studentId) {
            getStudentInfo({ studentId }).then(res => {
                const getInfo = res.data
                if (getInfo) {
                  const inifInfo = {
                    ...getInfo,
                    registeredRegion:getInfo.registeredRegion.split(':').filter(function (s) {return s && s.trim()}),
                    contactRegion:getInfo.contactRegion.split(':').filter(function (s) {return s && s.trim()}),
                    carId:[getInfo.carId],
                    sex:[getInfo.sex],
                    country:[getInfo.country],
                    typeId:[getInfo.typeId],
                    learningType:[getInfo.learningType],
                    source:[getInfo.source],
                    birthday:new Date(getInfo.birthday),
                    idnumEnd:new Date(getInfo.idnumEnd),
                    idnumStart:new Date(getInfo.idnumStart),
                  }
                  setDetailInfo({...inifInfo})
                  setFileList([...getInfo.fileList])
                }
            })
        }
    }, [])

    const renderUploadData = () => { // 新增和修改是一个流程，查看的数据来源和结构都发生了变化
      const params = {
        mode,
        fileList
      }
      return <PictureDetail {...params} />
    }

    return (
        <div className="ax-tab-wrapper ipx">
            <Tabs tabs={tabs}
                initialPage={0}
                renderTab={tab => <span className="tab-bars-height">{tab.title}</span>}>
                  <div className="tabs-content">
                    {detailInfo && <BasicForm info={detailInfo}/>}
                  </div>
                  <div className="tabs-content">
                    <div className="relative flex-item flex" style={{height:'100%'}}>
                      {renderUploadData()}
                    </div>
                  </div>
            </Tabs>
        </div>
    )
}
export default Index;

