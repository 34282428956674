import React, {useEffect, useState} from "react";

import {getSchoolList} from '@/service/chart'

let isLoading = false // 是否正在加载
let isLoadedSchools = false // 是否加载完成
let _schools = []
const init = [{key: '0', label: '全部', value: ''}]

function getSchools() {
  getSchoolList().then( res => {
    isLoadedSchools = true
    const data = res.data
    if(data.length) { // 如果请求结果为空数组，阻断了渲染
      const tempArr = [...init]
      for(const item of data) { // 类型转换为下拉
        tempArr.push({
          key: item.id,
          label: item.name,
          value: item.id,
        })
      }
      _schools = tempArr // 改变引用
    }
  })
}

export default function useSchools() {
  const [schools, setSchools] = useState(init)

  useEffect(() => {
    if(_schools.length) {
      setSchools(_schools)
      return;
    }
    if(!isLoading){ // 控制多个实例只触发一次
      isLoading = true
      getSchools()
    }
    const timer = setInterval(() => {
      if(isLoadedSchools) { // 加载完成
        if(_schools.length) { // 有数据渲染，避免不必要的更新
          setSchools(_schools)
        }
        clearInterval(timer)
      }
    }, 100)

    return () => {
      clearInterval(timer)
    }
  })

  return [schools]
}
