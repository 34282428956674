import DropdownCondition from './DropdownCondition'
import DropdownSchool from './DropdownSchool'
import DropdownSubject from './DropdownSubject'

const SchoolDropdown = DropdownSchool(DropdownCondition)
const SubjectDropdown = DropdownSubject(DropdownCondition)

export {
  DropdownCondition,
  SchoolDropdown,
  SubjectDropdown
}
