import React, { useState, useEffect } from 'react'

import Sign from '@/components/sign';
import LiBtns from '@/components/FlexBtn';

import 'isigning-open/esm/styles.less'
/**
 * 签字
 * @param props
 * @returns
 */
function SignDlg(props: Picture.signProps): JSX.Element {
  const { fileStr, studentId, type, onSave, unionId, } = props
  const [step] = useState(0)
  const [children, setChildren] = useState(null)

  const signCallback = () => { // 签字返回事件
    setChildren(showFile())
  }

  const showSign = (fileId) => {
    console.log(fileId)
    if (fileId) {
      onSave && onSave(fileId)
    }
  }

  const openSignBoard = () => setChildren(<Sign unionId={unionId} callBack={signCallback} type={type} studentId={studentId} onSave={showSign} />)

  const showFile = () => {
    const renderBtns = () => {
      if (step === 0) {
        return (<LiBtns arr={[{ name: '签字', onClick: openSignBoard }]} />)
      } else {
        const _btnProps = [
          {
            name: '重新签字',
            onClick: openSignBoard
          },
          {
            name: '提交',
            onClick: openSignBoard
          }
        ]
        return (
          <LiBtns arr={_btnProps} />
        )
      }
    }

    const renderImg = () => {
      const imgProps = {
        src: fileStr,
        className: 'pre-image',
        alt: '',
      }
      return <img {...imgProps} />
    }

    return (
      <>
        <div className="preview">
          {renderImg()}
        </div>
        <div className="btns ipx">
          {renderBtns()}
        </div>
      </>
    )
  }

  useEffect(() => {
    setChildren(showFile())
  }, [])

  return (
    <>
      {children}
    </>
  )
}

export default SignDlg
