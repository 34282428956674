// candidatesStatistics

import React, {useEffect, useState} from "react";

import {SchoolDropdown} from "./DropdownCondition/index";
import SubjectBar from './Charts/SubjectBar'
import {candidatesStatistics} from '@/service/chart'

import styles from './card.module.less'

function CandidatesStatistics(props) {
  const [chartData, setChartData] = useState([])
  const [orgId, setOrgId] = useState(null)

  useEffect(() => {
    requestData()
  }, [orgId])

  const requestData = () => {
    candidatesStatistics({
      orgId,
    }).then(res => {
      const data = res.data
      if(data.length) {
        setChartData(data)
      }
    })
  }

  const schoolSelectedChange = (value) => {
    setOrgId(value)
  }

  return (
    <div className={styles.card}>
      <div className={styles.title}>待考学员数量合计</div>
      <div className={styles.condItem}>
        <SchoolDropdown dropdownChange={schoolSelectedChange} />
      </div>

      <SubjectBar data={chartData}  />
    </div>
  )

}

export default CandidatesStatistics

