import React, { useEffect,useState } from 'react'
import { Toast,Modal } from 'antd-mobile';
import { createForm } from 'rc-form';
import RenderBtns from "../../components/FlexBtn/index";
import styles from './index.module.css';
import LiCreateForm from '../../components/createForm/index'
import {fromTableName,fromTableType} from './index.data'
import { getCountry } from '@/service/erp/system'
import util from '../../utils/util';
const alert = Modal.alert;

const Index:React.FC<{
  template: School.Template;
  firstStep: API.ImageFileRenderCondition;
  stepClickHandler: (step: number) => void;
  form:any;
}> = (props) => {
  const [contactRegionchange,setContactRegionchange] = useState(false)
  const countryList = [{code: '156',id: '1',name: '中国'}];
  const templateErtificate = [{ dictId: '2', id: '377590821744788', idCode: 'A', idName: '居民身份证', templateId: '260461583749268' }];
  const [country, setCountry] = useState(countryList)
  const [templateErtificateList, setTemplateErtificateList] = useState(templateErtificate)
  const [regionBySource, setRegionBySource] = useState([{ label: '省', value: '1', children: [{ label: '市', value: '2', children: [{ label: '区', value: '3' }] }] }])

  // 获取本地的数据
  useEffect(()=>{
    const getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
    if (getIdentutyInfo) {
      const param = {
        birthday: new Date(getIdentutyInfo.birthday),
        country: getIdentutyInfo.country,
        idnum: getIdentutyInfo.idnum,
        idnumEnd: new Date(getIdentutyInfo.idnumEnd),
        idnumStart: new Date(getIdentutyInfo.idnumStart),
        name: getIdentutyInfo.name,
        registeredAddress: getIdentutyInfo.registeredAddress,
        registeredRegion: getIdentutyInfo.registeredRegion,
        sex: getIdentutyInfo.sex,
        source: getIdentutyInfo.source,
        typeId: getIdentutyInfo.typeId
      }
      props.form.setFieldsValue(param)
    }
  },[sessionStorage.getItem('modifyInfo')])

  // 设置下拉框数据
  useEffect(()=>{
    getCountry().then((res) => {
      if (res.code == 'success') {
        setCountry([...res.data])
      }
    }).catch(()=>{
      return null
    })

    if (props.template.templateErtificateList) {
      setTemplateErtificateList([...props.template.templateErtificateList])
    }
  },[props.template.templateErtificateList])

// 监听学员来源改变
useEffect(()=>{
  const getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
  const localSourc = getIdentutyInfo ? getIdentutyInfo.source+'' : ''
  const source = props.form.getFieldValue('source')+'' || 'A'

  util.getSource(source).then((res) => {
    setRegionBySource(res)
    if (localSourc!=source) {
      props.form.setFieldsValue({
        'registeredRegion' : [],
      })
      setContactRegionchange(true)
    } else {
      props.form.setFieldsValue({
        'registeredRegion' : getIdentutyInfo.registeredRegion
      })
      setContactRegionchange(false)
    }
  }).catch(()=>{
    return null
  })
},[props.form.getFieldValue('source')+''])

  // 点击下一步
  const onSubmit = ()=>{
    props.form.validateFields({ force: true }, (error:any) => {
      const today = new Date();
      let flgNmber = 0
      let flag = true
      const limit = today.getTime() + 3600 * 24 * 3 * 1000
      const identutyInfo = props.form.getFieldsValue()
      const idNumInfo = util.getInfoByIdNumber(identutyInfo.idnum);
      console.log(identutyInfo,idNumInfo,(identutyInfo.typeId+'' === '2'),'identutyInfo');
      if (identutyInfo.name === '') {
        Toast.fail('请正确输入姓名', 1);
      } else if (!identutyInfo.typeId || JSON.stringify(identutyInfo.typeId) === '[]') {
        Toast.fail('请正确选择证件类型', 1);
      } else if (identutyInfo.idnum === '') {
        Toast.fail('请输入证件号码', 1);
      } else if ((identutyInfo.typeId+'' === '2') && !idNumInfo) {
        Toast.fail('请正确输入证件号码', 1);
        flgNmber++
      } else if (!identutyInfo.sex || JSON.stringify(identutyInfo.sex) === '[]') {
        Toast.fail('请正确选择性别', 1);
      } else if (!identutyInfo.birthday || JSON.stringify(identutyInfo.birthday) === '[]') {
        Toast.fail('请正确选择出生日期', 1);
      } else if (!identutyInfo.country || JSON.stringify(identutyInfo.country) === '[]') {
        Toast.fail('请正确选择国籍', 1);
      } else if (!identutyInfo.idnumStart || JSON.stringify(identutyInfo.idnumStart) === '[]') {
        Toast.fail('请正确选择证件有效期始', 1);
      } else if (!identutyInfo.idnumEnd || JSON.stringify(identutyInfo.idnumEnd) === '[]') {
        Toast.fail('请正确选择证件有效期止', 1);
      } else if (!identutyInfo.source || JSON.stringify(identutyInfo.source) === '[]') {
        Toast.fail('请正确选择学生来源', 1);
      } else if (!identutyInfo.registeredRegion || JSON.stringify(identutyInfo.registeredRegion) === '[]') {
        Toast.fail('请正确选择登记行政区域', 1);
      } else if (identutyInfo.registeredAddress === '') {
        Toast.fail('请正确输入详细地址', 1);
      }

      for (const key in identutyInfo) {
        if (!identutyInfo[key] || JSON.stringify(identutyInfo[key]) === '[]') {
          flgNmber++
        }
      }
      if (identutyInfo.typeId+'' === '2') {
        const idNumInfo = util.getInfoByIdNumber(identutyInfo.idnum);
        const d = identutyInfo.birthday
        const datetime= d.getFullYear() + '-' +((d.getMonth() + 1)<10 ? '0'+ (d.getMonth() + 1):(d.getMonth() + 1) ) + '-' + (d.getDate()<10 ? '0'+d.getDate(): d.getDate())

        if (idNumInfo && (identutyInfo.sex+'' !== idNumInfo.sex+'')) {
          flag = false
          return Toast.fail('身份证号码与性别不匹配', 1);

        }
        if (idNumInfo && (datetime !== idNumInfo.birthday)) {
          flag = false
          return Toast.fail('身份证号码与生日不匹配', 1);
        }
      }
      if (flgNmber === 0) {
        const getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo'));
        const modifyInfo = contactRegionchange ? {...getIdentutyInfo,...identutyInfo,contactRegion:[]} : {...getIdentutyInfo,...identutyInfo}
        sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
        if (limit > new Date(identutyInfo.idnumEnd).getTime()) {
          flag = false
          alert('提示',  `请注意，证件将在三日内过期，可能会被退办`, [
            { text: '取消', onPress: () => {return} },
            { text: '继续', onPress: () => {
              props.stepClickHandler(1);
            } },
          ])
        }
        if (flag) {
          props.stepClickHandler(1);
        }
      }
    })
  }
  // 按钮组
  const btnsArr1 = [
    {
      name:'下一步',
      className:'btn-next',
      onClick: function() {
        onSubmit();
      }
    },
  ]

  const renderFromTableName = ()=>{
    const startRender = regionBySource && templateErtificateList && country
    return startRender ? <div>
        <LiCreateForm fromTable={fromTableName()} Props={props} />
        <LiCreateForm fromTable={fromTableType(country,templateErtificateList,regionBySource)} Props={props} />
      </div> : null
  }

  return(
    <div className={styles['identity-info']}>
      {renderFromTableName()}
      <div className='ipx'><RenderBtns arr={btnsArr1} /></div>
    </div>
  )
}
export default createForm()(Index);
