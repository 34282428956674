import React, {useEffect, useState} from "react";

import TimeSetting from './TimeSetting'
import {SchoolDropdown} from "./DropdownCondition/index";
import TrainingLine from './Charts/TrainingLine'
import {trainingSituationStatistics} from '@/service/chart'

import styles from './card.module.less'

function TrainingSituationStatistics(props) {
  const [chartData, setChartData] = useState([])
  const [orgId, setOrgId] = useState(null)
  const [dateType, setDateType] = useState(0)


  useEffect(() => {
    requestData()
  }, [ orgId, dateType])

  const requestData = () => {
    trainingSituationStatistics({
      orgId,
      dateType,
    }).then(res => {
      const data = res.data
      if(data.length) {
        const trainingArr = []
        const licensingArr = []
        for(const item of data) {
          console.log(item)
          trainingArr.push({
            date: item.dateTime,
            name: '办结学员人数',
            count: item.completedStudentCount
          })

          licensingArr.push({
            date: item.dateTime,
            name: '领证学员人数',
            count: item.licensingStudentCount
          })
        }

        setChartData([...trainingArr, ...licensingArr])
      }
    })
  }

  const selectedChangeHandler = (dateType) => {
    setDateType(dateType.id)
  }

  const timeSettingProps = {
    title: '日期范围',
    items: [{id: 0, name: '近7天', key: 0}, {id: 1, name: '近30天', key: 1}, {id: 2, name: '近半年', key: 2}],
    selectedChange: selectedChangeHandler
  }

  const schoolSelectedChange = (value) => {
    setOrgId(value)
  }

  const defs = [{
    dataKey: 'count',
    min: 0,
    type: 'linear',
    minTickInterval: 1,
    formatter(value) {
      return `${value} 人`;
    },
  }];

  return (
    <div className={styles.card}>
      <div className={styles.title}>学员培训情况</div>
      <div className={styles.condItem}>
        <TimeSetting {...timeSettingProps} />
      </div>
      <div className={styles.condItem}>
        <SchoolDropdown dropdownChange={schoolSelectedChange} />
      </div>

      <TrainingLine data={chartData} defs={defs} />
    </div>
  )

}

export default TrainingSituationStatistics
