import React from "react";
import { Chart, Axis, Geom, Tooltip, Legend } from 'bizgoblin';

function TrainingLine({data = [], defs = [], padding = [30,30,40,40]}) {

  const renderLegend = () => {
    function marker(x, y, r, ctx) {
      ctx.lineWidth = 1;
      ctx.strokeStyle = ctx.fillStyle;
      ctx.moveTo(x - r - 3, y);
      ctx.lineTo(x + r + 3, y);
      ctx.stroke();
      ctx.arc(x, y, r, 0, Math.PI * 2, false);
      ctx.fill();
    }

    return <Legend marker={marker} offsetY={-8} />
  }

  return (
    <Chart width="100%" data={data} defs={defs} pixelRatio={window.devicePixelRatio * 2} padding={padding}>
      <Axis dataKey="date" label={{ rotate: 0.4, textAlign: 'left'}} labelOffset={10} />
      <Axis dataKey="count" />
      <Tooltip showCrosshairs showItemMarker={false}  />
      {renderLegend()}
      <Geom geom="line" position={'date*count'} color="name" />
      <Geom geom="point" position={'date*count'} color="name" style={{ lineWidth: 1, stroke: '#FFF' }} />
    </Chart>
  )
}

export default TrainingLine
