import { useEffect, useState } from 'react';
import routes from "../../router/routes";
import renderRoutes from "../../router/renderRoutes";
import { useHistory } from "react-router-dom";
import ComSteps from "../../components/steps/index";
import { getTemplateInfo } from '@/service/erp/system'
import { getStudentBaseInfo } from '@/service/student/index'
import './index.css'

const steps = [
  {
    title: '身份信息',
  },
  {
    title: '登记信息',
  },
  {
    title: '电子照片',
  }
]
// 路由数组
const paths = ["/home/identityInfo", "/home/registrationInfo", "/home/uploadData"]
// 模拟小程序传参
// const paths = ["/home/uploadData?token=eyJhbGciOiJIUzI1NiIsInppcCI6IkdaSVAifQ.H4sIAAAAAAAAACWNwQrCMBAFf0X2bEp2E5uknkQEL3pR8LypiURqlcaKIP67Ke5xmDf7geszQQPKSJIyGsGqbYX2HIQLFIUO1HqOJrJzMIfET2iwRoPO4ULOIY--rKmmWpO2ZK022k5izoXzPYvzkF6pvxTG47kwNFQhVrLC5e7uUxdmB448pCKE9-NfJ01qqrddCn35CKfNers6FmXMYdjzLUwh65Qsp1DD9weAAVrTyAAAAA.6Ft2FJxJoiFIEuGspZnSY5bcUBM7qBKUV4VLdkN1Fhw&orgId=262642482884749"]
// const paths = ["/home/uploadData"]

const Index: React.FC = (props: any) => {
  console.log('hooooooooooooome', props)
  const history = useHistory()
  const [template, setTemplate] = useState({})
  const [uploadNeedData, setUploadNeedData] = useState({})
  const [current, setCurrent] = useState(0)
  const hash = history.location.pathname

  // 路由改变之后对应的current改变
  const getKey = (arr: Array<string>, value: string) => {
    let key = 0
    arr.forEach((element: string, index: number) => {
      if (element == value) {
        key = index
      }
    });
    return key
  }

  useEffect(() => {
    setCurrent(getKey(paths, hash))
  }, [hash])

  useEffect(() => {
    // 这里判断是否编辑
    if (props.location.query) {
      const studentId = props.location.query.studentId;
      getStudentBaseInfo({
        studentId: studentId
      }).then((res) => {
        if (res.code == 'success') {
          const getInfo = res.data
          const modifyInfo = {
            ...res.data,
            registeredRegion: getInfo.registeredRegion.split(':').filter(function (s) { return s && s.trim() }),
            contactRegion: getInfo.contactRegion.split(':').filter(function (s) { return s && s.trim() }),
            carId: [getInfo.carId],
            country: [getInfo.country],
            sex: [getInfo.sex],
            typeId: [getInfo.typeId],
            learningType: [getInfo.learningType],
            source: [getInfo.source],
            birthday: new Date(getInfo.birthday),
            idnumEnd: new Date(getInfo.idnumEnd),
            idnumStart: new Date(getInfo.idnumStart),
          }
          sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
        }
      }).catch(() => {
        return null
      })
    }
  }, [props.location + ''])

  useEffect(() => {
    getTemplateInfo().then((res) => {
      if (res.code == 'success') {
        setTemplate(res.data)
        const mobileShow = res.data.mobile + ''
        sessionStorage.setItem('mobileShow', mobileShow);
      }
    }).catch(() => {
      return null
    })
  }, [])

  const changeStep = (currentNum: number) => {
    // if(currentNum === 0) {
    //   history.push(paths[currentNum])
    // } else {
    //   history.replace(paths[currentNum])
    // }
    history.replace(paths[currentNum])
  }

  useEffect(() => {
    changeStep(current)
  }, [current])

  // 步数
  const stepHandler = (step = 1) => {
    return current + step
  }

  // 改变current
  const stepClickHandler = (step: number, firstStep?: School.Source, studentInfo?: Student.StudentInfo) => {
    const curr = stepHandler(step)
    setCurrent(curr);
    setUploadNeedData({
      firstStep,
      studentInfo
    })
  }

  return (
    <div className="app">
      <div className='steps-box'>
        <ComSteps step={steps} current={current} />
      </div>
      <div className='interval' />
      <div className="relative flex-item flex ipx">
        {renderRoutes(routes, { stepClickHandler, template, ...uploadNeedData })}
      </div>
    </div>
  )
}
export default Index;
