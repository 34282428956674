import React, { useEffect, useState } from 'react'
import styles from '../registration/index.module.less';
import { List, Picker, Modal } from 'antd-mobile';
import { submitPhotoList, getPhoto, saveElectronic, getYzmCode, getPhotoPrice } from '@/service/open'
import qs from 'qs'
import result from '@/static/images/result.png';
import userBg from '@/static/images/userBg.png';
import jszbg from '@/static/images/jszbg.png';

import { redirectTo, navigateBack } from '@/utils/wxApi'
import { getFileInfo } from '../../uploadData/util/urlCreator'


const SelfPhoto: React.FC<any> = (props) => {


    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { unionid = '' } = query || {};

    // 省市区
    const [provinces, setProvinces] = useState<any>([]);
    const [citys, setCitys] = useState<any>([]);
    const [areas, setAreas] = useState<any>([]);

    const [valProvince, setValProvince] = useState<string>("");
    const [valCity, setValCity] = useState<string>("");
    const [valArea, setValArea] = useState<string>("");

    const [current, setCurrent] = useState<string>("");

    const [fileId, setFileId] = useState<string>("");

    const [payData, setPayData] = useState<any>({});

    const [yzmShow, setYzmShow] = useState<boolean>(false);
    const [yzmImg, setYzmImg] = useState<string>('');
    const [captcha, setCaptcha] = useState<string>('');
    const [captchaKey, setCaptchaKey] = useState<string>('');


    const [photoData, setPhotoData] = useState<any>({});

    const [inputFile, setInputFile] = useState<string>('');
    const [imgFile, setImgFile] = useState<any>({});


    const [submitPrice, setSubmitPrice] = useState<string>('');

    useEffect(() => {
        loadPhoto();
    }, [])

    const loadPhoto = () => {

        getPhoto(unionid).then((res: any) => {
            const data = res.data || {};
            const code = res.code;
            if (code === '999') {
                Modal.alert('提示', res.msg, [
                    {
                        text: '我知道了', onPress: () => {
                            navigateBack();
                        }
                    },
                ])
                return false;
            }
            if (data.fileId) {
                setFileId(data.fileId);
                setCurrent('4');
                setPhotoData(data || {});
            } else {
                if (data.orgRegion) {
                    const regions = data.orgRegion?.split(':') || [];
                    if (regions[0] && regions[1]) {
                        setValProvince(regions[0]);
                        setValCity(regions[1]);
                        goToShot(regions[0], regions[1]);
                    }
                } else {
                    getCity(1, 1);
                    setCurrent('1');
                }
            }
        })
    }
    const getCity = (id: number, type: number) => {
        submitPhotoList({ id }).then((res: any) => {
            const data = res.data;
            data.forEach(item => {
                item.label = item.name;
                // item.value = item.region;
                item.value = item.id;
            })
            if (type == 1) {
                setProvinces(data || []);
            } else if (type == 2) {
                setCitys(data || []);
            } else if (type == 3) {
                setAreas(data || []);
            }
        })
    }

    const onProvincesChange = (value) => {
        const val = value.join('');
        if (val) {
            setCitys([]);
            setAreas([]);
            setValProvince(val);
            setValCity('');
            setValArea('');
            getCity(val, 2);
        }
    }
    const onCityChange = (value) => {
        const val = value.join('');
        if (val) {
            setAreas([]);
            setValCity(val);
            setValArea('');
            getCity(val, 3);
        }
    }
    const onAreaChange = (value) => {
        const val = value.join('');
        setValArea(val);
    }

    const goCurrent = (current: string) => {
        setCurrent(current);
    }

    const fileChange = (e) => {
        const files = e.target.files;
        const file = files?.length ? files[0] : null;
        setCaptcha('');
        setCaptchaKey('');
        setImgFile(file);
        onUpdateFile(file);
    }

    const onUpdateFile = (file, captchaKey = '', captcha = '') => {
        const orgRegion = valProvince + ":" + valCity + ":" + valArea;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('orgRegion', orgRegion);
        formData.append('unionId', unionid);
        if (captchaKey && captcha) {
            formData.append('captchaKey', captchaKey);
            formData.append('captcha', captcha);
        }
        if (file) {
            setInputFile('');
            saveElectronic(formData).then(res => {
                const data = res.data;
                if (res.code === '999') {
                    setYzmShow(true);
                    getYzm();
                }
                else if (data.fileId) {
                    setYzmShow(false);
                    setPayData(data);
                    setFileId(data.fileId);
                    setCurrent('3');
                }
            })
        }
    }

    const getYzm = () => {
        getYzmCode().then(yzm => {
            setYzmImg(yzm.data.base64Img);
            setCaptchaKey(yzm.data.captchaKey);
        })
    }

    const photoSubmit = () => {
        if (payData.electronicPhoto === 'used') {
            setCurrent('4');
        } else {
            redirectTo(`/ucenter/pages/pay/index?fileId=${payData.fileId}&wsyFileId=${payData.wsyFileId}&regionId=${valCity}&source=supplement&studentId=${payData.studentId}`);
        }
    }

    const onYzmSave = () => {
        onUpdateFile(imgFile, captchaKey, captcha);
    }

    const goToShot = (valProvince, valCity) => {
        if (!valProvince || !valCity) {
            Modal.alert('错误', '请选择报考的城市!');
            return
        }
        getPhotoPrice(valCity).then(res => {
            console.log(res);
            const data = res.data;
            setSubmitPrice(data.submitPrice)
        });
        goCurrent('2')
    }

    return (
        <div className={styles['identity-info']}>
            {
                current == '1' &&
                <div className={styles['city-box']}>
                    <div className={styles['city-header']}>
                        <h3>请选择您报考的城市</h3>
                    </div>
                    <div className={styles['city-list']}>
                        <List>
                            <Picker data={provinces} value={[valProvince]} onOk={onProvincesChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    省/直辖市：
                                </List.Item>
                            </Picker>
                        </List>
                        <List>
                            <Picker data={citys} value={[valCity]} onOk={onCityChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    市：
                                </List.Item>
                            </Picker>
                        </List>
                        <List>
                            <Picker data={areas} value={[valArea]} onOk={onAreaChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    区县：
                                </List.Item>
                            </Picker>
                        </List>
                    </div>
                    <div className={styles.btns}>
                        <button onClick={() => goToShot(valProvince, valCity)}>去拍摄</button>
                    </div>
                </div>
            }
            {
                current === '2' &&
                <div className={styles['ps-box']}>
                    <div className={styles['psyq']}>
                        <img src={userBg} alt="" />
                        <div>
                            <strong>拍摄要求：</strong>
                            <ol>
                                <li>1、在白色背景前拍摄</li>
                                <li>2、不戴眼镜、露出耳朵和额头</li>
                                <li>3、人像居中、双眼水平</li>
                                <li>4、中性表情、肩膀等高</li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles['pssm']}>
                        <img src={jszbg} alt="" />
                        <p className={styles['tips']}>
                            电子证件照用于驾驶证的照片打印，使用驾考专业人像处理服务，可免去单独交纸质照片，提高审核通过率。<span>￥{submitPrice}/次</span>
                        </p>
                    </div>
                    <div className={styles['btns']}>
                        <button>
                            <i></i>点击拍摄
                            <input value={inputFile} capture="camera" type="file" accept="image/*" onChange={fileChange} />
                        </button>
                    </div>
                </div>
            }
            {
                current === '3' &&
                <div className={styles['ps-confirm']}>
                    <div>
                        <img src={getFileInfo(fileId)} alt="" />
                        <div className={styles['btns']}>
                            <button>重新拍摄<input value={inputFile} capture="camera" type="file" accept="image/*" onChange={fileChange} /></button>
                            <button onClick={photoSubmit}>提交电子照片 </button>
                        </div>
                    </div>
                </div>
            }

            {
                current === '4' &&
                <div className={styles['ps-result']}>
                    <img src={result} alt="" />
                    <h3>提交成功</h3>
                    <div>
                        <p>提交时间：{photoData.submitTime}</p>
                        <p>驾考状态：{photoData.status}</p>
                    </div>
                    <div className={styles['btns']}>
                        <button onClick={() => goCurrent('5')}>查看电子证件照</button>
                    </div>
                </div>
            }
            {
                current === '5' &&
                <div className={styles['ps-preview']}>
                    <div>
                        <img src={getFileInfo(fileId)} alt="" />
                        <div className={styles['btns']}>
                            <button onClick={() => goCurrent('4')}>确定</button>
                        </div>
                    </div>
                </div>
            }
            {
                yzmShow && <div className={styles['ps-yzm']}>
                    <div onClick={() => { setYzmShow(false) }} className={styles['mask']}></div>
                    <div className={styles['yzm-content']}>
                        <h3>请输入验证码</h3>
                        <img onClick={getYzm} src={yzmImg} alt="" />
                        <input type="text" placeholder="请输入验证码" value={captcha} onChange={(e) => { setCaptcha(e.target.value) }} />
                        <div className={styles['btns']}>
                            <button onClick={onYzmSave}>确定</button>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
export default SelfPhoto;
