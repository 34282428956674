import React, {useEffect, useState} from "react";

import useSchools from './useSchools'
// import {getSchoolList} from '@/service/chart'

function DropdownSchool(Component) {
  return ({dropdownChange}) => {
    console.log('DropdownSchool')
    const [schools] = useSchools()

    return (
      <Component title="驾校" data={schools} dropdownChange={dropdownChange} />
    )
  }
}

export default DropdownSchool
