import React from "react";

import EnrollmentStatisticsBox from './EnrollmentStatisticsBox'
import TrainingSituationStatistics from "./TrainingSituationStatistics";
import PassRateBox from './PassRateBox'
import CandidatesStatistics from './CandidatesStatistics'
import LicenseMakingStatistics from './LicenseMakingStatistics'

import styles from  './index.module.less';

function Statistics() {
  return (
    <div className={styles.container}>
      <EnrollmentStatisticsBox />
      <TrainingSituationStatistics />
      <PassRateBox />
      <CandidatesStatistics />
      <LicenseMakingStatistics />
    </div>
  )
}

export default Statistics
