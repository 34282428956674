import IdentityInfo from '../page/identityInfo';
import RegistrationInfo from '../page/registrationInfo';
import UploadData from '../page/uploadData';
import StudentInfo from '../page/studentInfo';
import Index from '../page/detailPage';
import Statistics from '../page/Statistics'
import Home from '../page/home'
import SignUp from '../page/signup'
import Registration from '../page/signup/registration'
import BaseInfo from '../page/signup/baseInfo'
import UploadPhoto from '../page/uploadPhoto';
import PhotoID from '../page/signup/photo'
import Student from '../page/signup/student'
import Details from '../page/signup/details';

// 静态路由定义， path 必须是完整路径
const routes = [
  {
    path: '/home/identityInfo',
    component: IdentityInfo,
    // component: import('../page/identityInfo/identityInfo')
  },
  {
    path: '/home/registrationInfo',
    component: RegistrationInfo,
  },
  {
    path: '/home/uploadData',
    component: UploadData,
  },
  {
    path: '/signup/registration',
    component: Registration
  },
  {
    path: '/signup/baseInfo',
    component: BaseInfo
  },
  {
    path: '/signup/uploadData',
    component: UploadPhoto
  }
];
export const rootRoute = [
  {
    path: '/studentInfo',
    component: StudentInfo,
  },
  {
    path: '/detailPage',
    component: Index,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/statistics',
    component: Statistics
  },
  {
    path: '/signup',
    component: SignUp
  },
  {
    path: '/photoID',
    component: PhotoID
  },
  {
    path: '/detail',
    component: Details,
  },
  {
    path: '/student',
    component: Student
  }
]

export default routes;
