import {ReactElement} from "react";
import { Steps,Icon } from "antd-mobile";
import "./index.css";

const Step = Steps.Step;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ComSteps = (props: { step: Array<{title: string;}>;current: number;}) => {
  const { step,current } = props;
  type stepStaus = {
    wait: ReactElement,
    process: ReactElement,
    finish: ReactElement
  }
  type str = keyof stepStaus
  const statusIcon = (status: str, num:number) => {
    const icon = {
      wait: <div className='custom-icon'>{num}</div>,
      process: <div className='custom-icon-ack'>{num}</div>,
      finish: <div><Icon type='check-circle-o' /></div>
    }
    return icon[status]
  }

  const renderStep = (curr:number) => (
    step.map(({title}, index:number) => {
      let status:keyof stepStaus = 'wait'
      if(curr === index) {
        status = 'process'
      }else if(index < curr) {
        status = 'finish'
      }
      const titleNode = <div className='label'>{title}</div>
      return <Step title={titleNode} icon={statusIcon(status, index+1)} key={index} />
    })
  )

  const stepsProps = {
    current,
    direction: "horizontal",
    size: "small",
  }

  return (
    <div className='steps-box'>
      <Steps {...stepsProps}>
        {renderStep(current)}
      </Steps>
    </div>
  );
};

export default ComSteps;
