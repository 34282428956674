import {compose} from '../util/functions'
import WithRequire from "./WithRequire";
// import WithProfiler from './WithProfiler'

function BasicItemLayout(Component) {
  // return compose(WithRequire, WithProfiler)(Component)
  return compose(WithRequire)(Component)
}

export default BasicItemLayout
