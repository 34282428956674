import React, {useState} from "react";
import {Picker, Icon} from 'antd-mobile'

import styles from './index.module.less'

function DropdownCondition(props) {
  const {title, data, dropdownChange} = props
  const [txt, setTxt] = useState('全部')
  const [val, setVal] = useState([])

  const okHandler = (value) => {
    console.log(value)
    for(let i = 0; i<data.length; i++) {
      if(value == data[i].value) {
        setVal(value)
        setTxt(data[i].label)
        break;
      }
    }
    dropdownChange && dropdownChange(value)
  }

  return (
    <div className={styles.conditionItem}>
      <div className={styles.title}>{title}</div>
      <div className={styles.condition}>
        <Picker data={data} value={val} cols={1} onOk={okHandler}>
          <span className={styles.schoolName}>{txt} <Icon type="down" size={'xxs'} /></span>
        </Picker>
      </div>
    </div>
  )
}

export default DropdownCondition
