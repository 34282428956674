import React, { useCallback } from 'react'

import SignBoard from 'isigning-open/esm/signBoard/index';
import { uploadSignature } from '@/service/sign'
import { uploadSign } from '@/service/open'
import 'isigning-open/esm/styles.less'
import './index.css'


function Sign(props) {
  const { type, studentId, onSave, unionId } = props

  console.log('sign', type, studentId)

  const myref = useCallback((node) => {
    if (!node) {
      return
    }
    const signBoard = new SignBoard().init({
      enabled: true,
      container: node,
      // container: document.querySelector('.sign-board'),
      toolsBar: 'SIGN_TOOLS_BAR',
      toolsBarConfig: {
        // isUploadSign: true,
        submit(e) {
          console.log(e)
          const data = {
            nodeJSISignVo: {
              data: e.points,
              source: {
                width: e.boardWidth,
                height: e.boardHeight
              },
              view: {
                color: "black",
                fake_pressure: e.signBoard.fakePressure,
                thickness: e.signBoard.thickness
              },

            },
            signatureType: type,
            studentId,
          }
          if (unionId) {
            data.unionId = unionId;
            uploadSign(data).then((res) => {
              if (res.data) {
                onSave && onSave(res.data)
              }
            })
          } else {
            uploadSignature(data).then((res) => {
              if (res.data) {
                onSave && onSave(res.data)
              }
            })
          }
          return false;
        },
        // nextSubmit(e) {
        //   return Date.now();
        // },
        callBack() {
          console.log('sign-board callback')
          props.callBack && props.callBack();
        }
      },
    });
    signBoard.addEventListener('onPushPoint', function (e) {
      // console.log(JSON.stringify(e));
    });

    const touchStart = (e) => {
      if (e.target.nodeName === "CANVAS") {
        e.preventDefault();
      }
    };
    const ele = document.querySelector(".sign-board");
    ele.addEventListener("touchstart", touchStart);
    return () => {
      ele.removeEventListener("touchstart", touchStart);
    };
    // return () => {
    //   cleanup
    // };
  }, []);

  return (
    <div ref={myref} className="sign-board ax-register-board" />
  )
}

export default Sign
