import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * router 渲染函数
 * @param {
 *  component
    render: func
    children: func
    path: string | string[]
    exact: bool
    strict: bool
    location: object
    sensitive: bool
 * } routes
 * @param { object } extraProps 额外的参数，可以为每个路由指定的组件传递
 * @param {
 *  location: object
    children: node(Route | Redirect)
 * } switchProps
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
  const renderChildren = () => {
    return routes.map((route, i) => {
      const rProps = {
        exact: route.exact || false,  // 默认开启精确匹配  https://reactrouter.com/web/api/Route/exact-bool
        strict: route.strict || false, // 默认关闭严格模式，https://reactrouter.com/web/api/Route/strict-bool
        key: route.key || i,
        path: route.path,
        render: (props) => { // props: {history, location, match, staticContext}
          return route.render ? (
            route.render({ ...props, ...extraProps, route: route })
          ) : (
            <route.component {...props} {...extraProps} route={route} />
          );
        },
      };
      return <Route {...rProps} key={rProps.key} />;
    });
  }

  return routes ? <Switch {...switchProps}>{renderChildren()}</Switch> : null;
};

export default renderRoutes;
