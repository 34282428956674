import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import styles from '../registration/index.module.less';
import { List, Picker, Modal } from 'antd-mobile';
import { checkStudent, getRegisterList, getStudentList, getStudentBaseInfo } from '@/service/open'
import qs from 'qs'
import { navigateBack } from '@/utils/wxApi'


const Student: React.FC<any> = (props) => {

    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { unionid = '', studentId = '', type = '' } = query || {};

    const history = useHistory()

    // 省市区
    const [provinces, setProvinces] = useState<any>([]);
    const [citys, setCitys] = useState<any>([]);
    const [areas, setAreas] = useState<any>([]);

    const [valProvince, setValProvince] = useState<string>("");
    const [valCity, setValCity] = useState<string>("");
    const [valArea, setValArea] = useState<string>("");

    const [current, setCurrent] = useState<string>("0");


    const [studentList, setStudentList] = useState<any>([]);

    useEffect(() => {
        loadInfo();
    }, [])

    const getData = (data) => {
        return {
            ...data,
            studentId: data.id,
            registeredRegion: data.registeredRegion.split(':').filter(function (s) { return s && s.trim() }),
            contactRegion: data.contactRegion.split(':').filter(function (s) { return s && s.trim() }),
            carId: [data.carId],
            country: [data.country],
            sex: [data.sex],
            typeId: [data.typeId],
            learningType: [data.learningType],
            source: [data.source],
            birthday: data.birthday && new Date(data.birthday),
            idnumEnd: data.idnumEnd && new Date(data.idnumEnd),
            idnumStart: data.idnumStart && new Date(data.idnumStart),
        }
    }
    const loadInfo = () => {
        if (type === 'again') {
            setCurrent('1')
            getCity(1, 1)
            return
        }
        if (studentId) {
            setCurrent('1')
            getStudentBaseInfo({
                studentId: studentId,
                unionId: unionid,
            }).then((res) => {
                if (res.code == 'success') {
                    const data = res.data;
                    const modifyInfo = getData(data);
                    sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));

                    const citys = data.orgRegion.split(':');
                    for (let i = 0; i < citys.length; i++) {
                        if (i == 0) {
                            getCity(1, i + 1);
                            setValProvince(citys[i])
                        } else {
                            getCity(parseInt(citys[i - 1]), i + 1);
                        }
                        if (i == 1) {
                            setValCity(citys[i])
                        } else if (i == 2) {
                            setValArea(citys[i])
                        }
                    }
                } else if (res.code === '999') {
                    Modal.alert('提示', res.msg, [
                        {
                            text: '我知道了', onPress: () => {
                                navigateBack();
                            }
                        },
                    ])
                    return false;
                } else {
                    getCity(1, 1);
                }
            })
            return;
        }
        checkStudent(unionid).then((res: any) => {
            const data = res.data;
            if (data && data.id) {
                const modifyInfo = getData(data);
                const citys = data.orgRegion.split(':');
                if (data.unionId && citys.length > 2) {
                    sessionStorage.setItem('modifyInfo', JSON.stringify(modifyInfo));
                    const city = citys[0] + ":" + citys[1] + ":" + citys[2]
                    history.replace(`/signup?unionid=${data.unionId}&city=${city}`);
                } else {
                    setCurrent('1')
                    getCity(1, 1);
                }
            } else {
                getCity(1, 1);
                getStudentList(unionid).then((res: any) => {
                    const data = res.data || [];
                    if (data && data.length > 0) {
                        setStudentList(data);
                        setCurrent('2')
                    } else {
                        setCurrent('1')
                    }
                })
            }
        })
    }
    const getCity = (id: number, type: number) => {
        getRegisterList({ id }).then((res: any) => {
            const data = res.data;
            data.forEach(item => {
                item.label = item.name;
                item.value = item.id;
            })
            if (type == 1) {
                setProvinces(data || []);
            } else if (type == 2) {
                setCitys(data || []);
            } else if (type == 3) {
                setAreas(data || []);
            }
        })
    }

    const onProvincesChange = (value) => {
        const val = value.join('');
        if (val) {
            setCitys([]);
            setAreas([]);
            setValProvince(val);
            setValCity('');
            setValArea('');
            getCity(val, 2);
        }
    }
    const onCityChange = (value) => {
        const val = value.join('');
        if (val) {
            setAreas([]);
            setValCity(val);
            setValArea('');
            getCity(val, 3);
        }
    }
    const onAreaChange = (value) => {
        const val = value.join('');
        setValArea(val);
    }
    const goToSign = () => {
        if (!valProvince || !valCity || !valArea) {
            Modal.alert('错误', '请选择报考的城市!');
            return
        }
        const city = valProvince + ':' + valCity + ':' + valArea;
        history.replace(`/signup?unionid=${unionid}&city=${city}`);
    }
    const getResult = (result) => {
        const obj = {
            otherServiceSide: '其他端已保存（驾校）',
            underReview: '资料审核中',
            auditSucceeded: '资料审核通过',
            auditFailed: '资料审核失败',
        }
        return <strong className={result}>{obj[result]}</strong>
    }
    const handleClick = (item) => {
        history.push(`/detail?studentId=${item.id}&mode=${1}`);
    }
    // 再次报名
    const handleSign = () => {
        history.push(`/student?type=again&unionid=${unionid}`);
        location.reload()
    }
    // 重新报名
    const handleWrite = (item) => {
        // const citys = item.orgRegion.split(':');
        // const city = citys[0] + ":" + citys[1] + ":" + citys[2]
        // history.push(`/signup?studentId=${item.id}&unionid=${unionid}&city=${city}`);
        history.push(`/student?studentId=${item.id}&unionid=${unionid}`);
        location.reload()
    }
    return (
        <div className={styles['identity-info']}>
            {
                current == '1' &&
                <div className={styles['city-box']}>
                    <div className={styles['city-header']}>
                        <h3>请选择您报考的城市</h3>
                    </div>
                    <div className={styles['city-list']}>
                        <List>
                            <Picker data={provinces} value={[valProvince]} onOk={onProvincesChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    省/直辖市：
                                </List.Item>
                            </Picker>
                        </List>
                        <List>
                            <Picker data={citys} value={[valCity]} onOk={onCityChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    市：
                                </List.Item>
                            </Picker>
                        </List>
                        <List>
                            <Picker data={areas} value={[valArea]} onOk={onAreaChange} cols={1}>
                                <List.Item arrow="horizontal">
                                    区县：
                                </List.Item>
                            </Picker>
                        </List>
                    </div>
                    <div className={styles.btns}>
                        <button onClick={() => goToSign()}>去报名</button>
                    </div>
                </div>
            }
            {
                current === '2' &&
                studentList.map((item, index) => {
                    return <div key={index} className={styles['list-box']}>
                        <div>
                            <span>{item.name}</span>
                            <div>
                                {
                                    (item.status === '已拿证' || item.status === '退学') &&
                                    <label onClick={() => handleSign()}>再次报名</label>
                                }
                                {
                                    item.result === 'auditFailed' &&
                                    <label onClick={() => handleWrite(item)}>重新填写</label>
                                }
                                <label onClick={() => handleClick(item)}>报名详情</label>
                            </div>
                        </div>
                        <p><span>性别：</span>{item.gender === 0 ? '男' : '女'}</p>
                        <p><span>身份证号：</span>{item.idNum}</p>
                        <p><span>申请车型：</span>{item.carType}</p>
                        <p><span>提交时间：</span>{item.submitTime}</p>
                        <h4>
                            <span>审核结果：</span>
                            <strong>{getResult(item.result)}</strong>
                        </h4>
                        {
                            (item.result === 'auditSucceeded' && item.status)
                            && <>
                                <h5>
                                    <span>通过时间：</span>{item.time}
                                </h5>
                                <h5>
                                    <span>驾考状态：</span>{item.status}
                                </h5>
                                <h5>
                                    <span>流水号：</span>{item.serialNumber}
                                </h5>
                            </>
                        }
                        {
                            item.result === 'auditFailed'
                            && <>
                                <h5>
                                    <span>失败时间：</span>{item.time}
                                </h5>
                                <h5>
                                    <span>失败原因：</span>{item.reason}
                                </h5>
                            </>
                        }
                    </div>
                })
            }
        </div>
    )
}
export default Student;
