import React from "react";
import RequireText from "../item/requireText";

function WithRequire(Component) {
  return (props) => {
    const { requirement, ...otherProps } = props;
    console.log('1111111111111', props)
    return (
      <div className='tab-content flex-item'>
        <RequireText {...requirement} />
        {
          props.type === 15 ?
            <Component {...Object.assign(requirement, otherProps)} />
            :
            <Component {...otherProps} />
        }
      </div>
    )
  }
}

export default WithRequire
