import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import closest from '../util/closest';

import './dialog.css'

export default function Dialog(message: React.ReactNode): Record<string, unknown> {
  // const closed = false;

  // if (!title && !message) {
  //   // console.log('Must specify either an alert title, or message, or both');
  //   return {
  //     close: () => null,
  //   };
  // }

  const div: HTMLElement = document.createElement('div');
  div.id = 'pop_certCamera';
  document.body.appendChild(div);

  function close() {
    ReactDOM.unmountComponentAtNode(div);
    if (div && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  // const footer = actions.map((button: Action<React.CSSProperties>) => {
  //   // tslint:disable-next-line:only-arrow-functions
  //   const orginPress = button.onPress || function () { return null };
  //   button.onPress = () => {
  //     if (closed) {
  //       return;
  //     }

  //     const res = orginPress();
  //     if (res && res.then) {
  //       res
  //         .then(() => {
  //           closed = true;
  //           close();
  //         })
  //         .catch(() => null);
  //     } else {
  //       closed = true;
  //       close();
  //     }
  //   };
  //   return button;
  // });

  // function onWrapTouchStart(e: React.TouchEvent<HTMLDivElement>) {
  //   if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
  //     return;
  //   }
  //   const pNode = closest(e.target as Element, `.full-footer`);
  //   if (!pNode) {
  //     e.preventDefault();
  //   }
  // }

  ReactDOM.render(
    <div className="full-wrap">
      <div className="full-content">{message}</div>
    </div>,
    div,
  );

  return {
    close,
  };
}
