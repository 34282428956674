import React, {useState, useEffect, useRef} from 'react';
import {useThrottle} from 'ahooks';
import ReactDOM from 'react-dom';
import {ListView, PullToRefresh} from 'antd-mobile'

import {queryByPage} from '@/service/student/index'
import DataForm from './component/dataForm'
import './index.css';
import imgs from '../../static/images/dingbu.png'

const StudentInfo = () => {
  // 处理节流值
  const [queryNameAndID, setQueryNameAndID] = useState("")
  const throttledValue = useThrottle(queryNameAndID, {wait: 500})
  const inputRef = useRef(null)
  // 回到顶部状态
  const [show, switchShow] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const lv = useRef();
  const [lvHeight, setLvHeight] = useState(100)
  const [refreshing, setRefreshing] = useState(true)
  const orgList = useRef([])
  const listDataSource = new ListView.DataSource({
    rowHasChanged: (row1, row2) => row1 !== row2,
  })

  const dataSource = useRef(listDataSource);
  useEffect(() => {
    const hei = document.documentElement.clientHeight - 78;
    setLvHeight(hei)
  }, [])

  useEffect(() => {
    getStudentList(pageNum, throttledValue)
  }, [pageNum])

  // 获取所有学员列表
  const getStudentList = (val: number, keys: string) => {
    // 分页查询学生数据
    const params = {pageIndex: val, pageSize: 100, keys: keys}
    queryByPage(params).then(res => {
      if (res.code == 'success') {
        // if (res.data.rows.length < 1) {
        //   setRefreshing(false)
        //   return
        // }
        if (pageNum === 1) {
          dataSource.current = dataSource.current.cloneWithRows(res.data.rows)
          orgList.current = res.data.rows
        } else {
          dataSource.current = dataSource.current.cloneWithRows([...orgList.current, ...res.data.rows])
          orgList.current = [...orgList.current, ...res.data.rows]
        }
        setRefreshing(false)
      }
    })
  }
  // 上拉加载数据
  const onEndReached = () => {
    setPageNum(pageNum + 1)
  }
  // 下拉刷新数据
  const onRefresh = () => {
    setPageNum(1)
  }
  // 渲染列表
  const renderPersonalDetails = (item, sectionID, rowID) => {
    return <DataForm key={rowID} list={item}/>
  }
  // 条件搜索
  const handleClick = () => { // 筛选值
    getStudentList(pageNum, throttledValue)
    // 刷新列表
    setRefreshing(true)
  }

  // 监听滚动条 > 300 出现回到顶部图标
  useEffect(() => {
    const lvDOM = ReactDOM.findDOMNode(lv.current) as HTMLElement;
    const listener = () => {
      switchShow(lvDOM.scrollTop > 900)
    }
    lvDOM.addEventListener('scroll', listener);
    return () => lvDOM.removeEventListener('scroll', listener); // 组件销毁后，取消监听
  }, [show])

  // 回到顶部显示与隐藏
  const showToTop = () => {
    return show ? (
      <div className='back-top' onClick={() => {
        (ReactDOM.findDOMNode(lv.current) as HTMLElement).scrollTop = 0
      }}>
        <div className='back-top-content'>
          <img src={imgs} className='img-style' alt="顶部" />
        </div>
      </div>
    ) : null
  }

  return (
    <div className='outer-box ipx'>
      {/* 搜索框 */}
      <div className='query-box'>
        <input placeholder="请输入姓名/证件号" value={queryNameAndID}
               onChange={(e) => setQueryNameAndID(e.target.value)} ref={inputRef} className='search-form'/>
        <i className='icon-search' onClick={handleClick}/>
      </div>
      {/* 数据卡片 */}
      <div className='data-box'>
        <ListView
          ref={lv}
          dataSource={dataSource.current}
          renderRow={renderPersonalDetails}
          style={{
            height: lvHeight,
            overflow: 'auto',
          }}
          pageSize={5}
          pullToRefresh={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: 参数不全的错误
            <PullToRefresh
              refreshing={refreshing}
              onRefresh={onRefresh}  //下拉刷新
            />
          }
          scrollRenderAheadDistance={500}  //当一个行接近屏幕范围多少像素之内的时候，就开始渲染这一行
          onEndReached={onEndReached} //上拉加载
          onEndReachedThreshold={10}
        />
      </div>
      {/* 回到顶部按钮 */}
      {showToTop()}
    </div>

  )
}
export default StudentInfo;
