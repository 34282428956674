import React, { useState, useEffect } from 'react';
import { InputItem, Button, } from 'antd-mobile';
import { useHistory } from 'react-router-dom';

import { EnumInternetStatus, EnumInnerStatus, EnumSex } from '../../../utils/enumer'
import './dataForm.css';
import { getByText } from '@testing-library/dom';

const DataForm = (props) => {
  const { list } = props

  // 路由跳转
  let history = useHistory();

  // 按钮操作
  const btnAction = {
    select: 0,//查看
    update: 1,//编辑
    // 根据键获取值
    getByText(val) {
      return this[val]
    }
  }
  const btnGroups = [{
    type: 'ghost',
    name: '详情',
    className: 'detail-button',
    click: function () {
      // 详情事件
      handleClick(`/detailPage`, { studentId: list.studentId, mode: btnAction.select });
    }
  },
  {
    type: 'primary',
    name: '编辑',
    className: 'detail-button',
    click: function () {
      // 编辑事件
      handleClick(`/home/identityInfo`, { studentId: list.studentId, mode: btnAction.update });
    }
  },
  ]

  const handleClick = (pathname, query) => {
    return history.push({ pathname, query })
  }

  // 渲染button按钮
  const renderButtonGroup = (arr) => (
    arr.map((item, index) => {
      return <Button key={index} type={item.type} className={item.className} size="small" onClick={item.click}>{item.name}</Button>
    })
  )

  // 根据状态渲染编辑和查看详情按钮
  const dealState = () => {
    if (
      // 已保存:1、制证照审核不通过:5、退办:5
      list.status != EnumInternetStatus.已保存 &&
      list.status != EnumInternetStatus.制证照审核不通过 &&
      list.innerStatus != EnumInnerStatus.退办
    ) {
      // 1个按钮
      return true
    }
    return false;
  }
  // 处理互联网状态、业务状态、性别
  const findKey = (obj, value) => {
    for (let key in obj) {
      if (obj[key] == value) {
        return key
      }
    }
  }

  return (
    <div className='cardInfo'>
      <div className='text-data' onClick={() => handleClick(`/detailPage`, { studentId: list.studentId, select: btnAction.select })}>
        {/* 0是男 1是女 */}
        <div className='form-lable-top'><span className='form-label-name'>{list.name}</span><span className='form-label-sex'>（{findKey(EnumSex, list.sex) || "-"}）</span></div>
        <div className='form-lable-data'><span>证件号码：</span><span>{list.idnum}</span></div>
        <div className='form-lable-data'><span>申请车型：</span><span>{list.car}</span></div>
        <div className='form-lable-data'><span>互联网状态：</span><span>{findKey(EnumInternetStatus, list.status) || "-"}</span></div>
        <div className='form-lable-data'><span>业务状态：</span><span>{findKey(EnumInnerStatus, list.innerStatus) || "-"}</span></div>
        <div className='form-lable-data'><span>录入日期：</span><span>{list.created}</span></div>
      </div>
      <div className="button-group">
        {

          // 判断父组件传的flag  true:渲染两个按钮  false：渲染一个按钮  详情按钮
          dealState() ? renderButtonGroup(btnGroups.slice(0, 1)) : renderButtonGroup(btnGroups)
        }
      </div>
    </div>

  )
}
export default DataForm
