import util from '../../utils/util';

// 输入姓名的验证规则
const validateName = (rule, value, callback) => {
    if (value && value.length > 0) {
        callback();
    } else if (value.length === 0) {
        callback(new Error('请输入名字'));
    }
};
// 为不够10添加0的函数
// const p = (s) => {
//     return s < 10 ? '0' + s : s;
// };

// 证件号码的验证规则
const validateIdnum = (rule, value, callback, Props) => {
    let typeId = Props.form.getFieldValue('typeId') + ''
    let birthday = Props.form.getFieldValue('birthday') + ''
    let sex = Props.form.getFieldValue('sex') + ''
    if (value.length === 0) {
        callback(new Error('请输入证件号码号码'));
    }
    if (typeId == '2') { // 为身份证信息的时候验证规则
        const idNumInfo = util.getInfoByIdNumber(value);
        if (idNumInfo) {
            if (birthday === 'undefined') {
                Props.form.setFieldsValue({
                    'birthday': new Date(idNumInfo.birthday),
                })
            }
            if (sex === 'undefined') {
                Props.form.setFieldsValue({
                    'sex': [idNumInfo.sex]
                })
            }
            callback();
        } else {
            callback(new Error('身份证格式不正确'));
        }
    }
    callback();
};

// 身份证详细地址验证规则
const validateRegisteredAddress = (rule, value, callback) => {
    if (value && value.length > 0) {
        callback();
    } else if (value.length === 0) {
        callback(new Error('请输入身份证详细地址'));
    }
};

// 改变证件类型数组
const changeTemplateErtificateList = (templateErtificateList) => {
    return templateErtificateList.map((item) => {
        return {
            label: item.idCode + '：' + item.idName,
            value: item.dictId,
        };
    });
};
// 改变国籍数组
const changeCountryList = (countryList) => {
    return countryList.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });
};

// console.log(changeTemplateErtificateList(templateErtificateList));
// type:判断需要渲染的组件那种组件
// textName为名字
// valueName参数名字
// placeholder输入框的placeholder
// required是否必填
// verify为验证规则
// select下来框或者单选框的选择条件

export const fromTableName = (disabled) => {
    return [{
        type: 'input',
        textName: '姓名',
        valueName: 'name',
        placeholder: '请输入姓名',
        required: true,
        verify: validateName,
        disabled: disabled,
        maxLength: 25
    }, ];
};

export const fromTableType = (countryList, templateErtificateList, regionBySource, disabled) => {
    return [{
            type: 'select',
            textName: '证件类型',
            valueName: 'typeId',
            initVal: ['2'],
            placeholder: '',
            required: true,
            verify: {},
            select: changeTemplateErtificateList(templateErtificateList),
            disabled: disabled
        },
        {
            type: 'input',
            textName: '证件号码',
            valueName: 'idnum',
            placeholder: '请输入证件号码',
            required: true,
            verify: validateIdnum,
            maxLength: 18,
            disabled: disabled
        },
        {
            type: 'select',
            textName: '性别',
            valueName: 'sex',
            placeholder: '请选择性别',
            required: true,
            verify: {},
            select: [
                { label: '男', value: 0 },
                { label: '女', value: 1 },
            ],
            disabled: disabled
        },
        {
            type: 'timeSelect',
            textName: '出生日期',
            valueName: 'birthday',
            placeholder: '请选择时间',
            required: true,
            verify: {},
            disabled: disabled
        },
        {
            type: 'select',
            textName: '国籍',
            valueName: 'country',
            initVal: ['1'],
            placeholder: '',
            required: true,
            verify: {},
            select: changeCountryList(countryList),
            disabled: disabled
        },
        {
            type: 'timeSelect',
            textName: '证件有效期始',
            valueName: 'idnumStart',
            placeholder: '请选择时间',
            required: true,
            verify: {},
            disabled: disabled
        },
        {
            type: 'timeSelect',
            textName: '证件有效期止',
            valueName: 'idnumEnd',
            placeholder: '请选择时间',
            required: true,
            verify: {},
            disabled: disabled
        },
        {
            type: 'select',
            textName: '学员来源',
            valueName: 'source',
            placeholder: '',
            required: true,
            verify: {},
            select: [
                { label: '本地', value: 'A' },
                { label: '外地', value: 'B' },
                { label: '本省外地', value: 'C' },
            ],
            disabled: disabled,
        },
        {
            type: 'select',
            textName: '登记住所行政区划',
            valueName: 'registeredRegion',
            placeholder: '',
            required: true,
            verify: {},
            cols: 3,
            select: regionBySource,
            disabled: disabled,
            textClass: 'text-name-class'
        },
        {
            type: 'textarea',
            textName: '登记住所详细地址',
            valueName: 'registeredAddress',
            placeholder: '身份证详细地址',
            required: true,
            verify: validateRegisteredAddress,
            disabled: disabled,
            textareaClass: 'text-textarea-class'
        },
    ];
};