import React, {useMemo, useEffect, useState} from "react";

import TimeSetting from './TimeSetting'
import {SchoolDropdown} from "./DropdownCondition/index";
import TotalBar from './Charts/TotalBar'
import {enrollmentStatistics} from '@/service/chart'

import styles from './card.module.less'

function EnrollmentStatisticsBox() {
  const [chartData, setChartData] = useState([])
  const [orgId, setOrgId] = useState(null)
  const [time, setTime] = useState(null) //测试['2021-01-01', '2021-10-10']
  const [dateType, setDateType] = useState(0)

  useEffect(() => {
    requestData()
  }, [orgId, dateType || time])

  const requestData = () => {
    enrollmentStatistics({
      orgId,
      startTime: time ? time[0] : null,
      endTime: time ? time[1] : null,
      dateType,
    }).then(res => {
      if(res.data.length) {
        setChartData(res.data)
      }
    })
  }

  const selectedChangeHandler = (dateType) => {
    console.log('selectedChangeHandler', dateType)
    if(dateType.id === 9) {
      setTime([dateType.startTime, dateType.endTime])
    } else {
      setTime(null)
    }
    setDateType(dateType.id)
  }

  const timeSettingProps = useMemo(() => ({
    title: '日期范围',
    hasCustomer: true,
    items: [{id: 0, name: '近7天', key: 0}, {id: 1, name: '近30天', key: 1}, {id: 9, name: '自定义', key: 9}],
    selectedChange: selectedChangeHandler
  }), [])

  const schoolSelectedChange = (value) => {
    setOrgId(value)
  }

  const renderTime = () => {
    return time && time.length ? (<div className={styles.currTime}>{`${time[0]} ~ ${time[1]}`}</div>) : null
  }

  return (
    <div className={styles.card}>
      <div className={styles.title}>学员报名提交人数</div>
      <div className={styles.condItem}>
        <TimeSetting {...timeSettingProps} />
      </div>
      {renderTime()}
      <div className={styles.condItem}>
        <SchoolDropdown dropdownChange={schoolSelectedChange} />
      </div>

      <TotalBar data={chartData} />
    </div>
  )

}

export default EnrollmentStatisticsBox
