import util from '@/utils/util';
// 输入电话号码的验证规则
const validateMobile = (rule, value, callback) => {
    if (value.length === 0) {
        callback(new Error('请输入电话号码'));
    } else if (!util.REGEX_MOBILE.test(value)) {
        callback(new Error('请正确输入电话号码'));
    } else {
        callback();
    }
};
// 是否必填
const requiredTrue = (rule, value, callback) => {
    if (value.length === 0) {
        callback(new Error('请输入'))
    } else {
        callback();
    }
};

// 倒计时
const onExtraClick = () => {
    console.log(1)
};

// 下拉框点击ok
const selectOkCarId = (val, form, data) => {
    const value = val + ''
    let startAge = ''
    let endAge = ''
    let getIdentutyInfo = JSON.parse(sessionStorage.getItem('modifyInfo'))
    const age = util.getAgeByBirthday(getIdentutyInfo.birthday.slice(0, 10))
    data.forEach(element => {
        if (element.value == value) {
            startAge = element.startAge
            endAge = element.endAge
        }
    });
    if (age < startAge || age > endAge) {
        return `您的年龄为：${age}岁,该车型要求年龄必须在${startAge}-${endAge}岁`
    }
};

// 通过行政区域获取邮编
const selectOkContactRegion = (val, props, regionBySource) => {
    const number = val[2]
    const zipCode = util.findZipCode(regionBySource, number)
    props.form.setFieldsValue({
        'zipCode': zipCode
    })
}

// 对车型数字进行二次转换
const changeTemplateModelList = (arr) => {
    return arr.map((item) => {
        return {
            label: item.carName,
            value: item.dictId,
            startAge: item.startAge,
            endAge: item.endAge
        }
    })
}
const mobileShow = sessionStorage.getItem('mobileShow')
// type:判断需要渲染的组件那种组件
// textName为名字
// valueName参数名字
// placeholder输入框的placeholder
// required是否必填
// verify为验证规则
// select下来框或者单选框的选择条件
// editable是否不让编辑

export const fromTableMobile = (template, forbid, disabled) => {
    return [{
        type: 'input',
        textName: '电话号码',
        valueName: 'mobile',
        placeholder: '请输入电话号码',
        required: true,
        verify: validateMobile,
        disabled: disabled,
        maxLength: 11
    },
    {
        mobileShow: mobileShow,
        type: 'input',
        textName: '验证码',
        valueName: 'mcode',
        placeholder: '请输入验证码',
        required: true,
        extra: '获取验证码',
        hidden: true,
        hiddenVal: 'mcodeKey',
        onExtraClick: onExtraClick,
        verify: requiredTrue,
        disabled: disabled,
        forbid: forbid,
    },
    {
        type: 'input',
        textName: '固定电话',
        valueName: 'phone',
        placeholder: '请输入固定电话',
        required: false,
        verify: {},
        disabled: disabled,
    },
    {
        type: 'input',
        textName: '电子邮箱',
        valueName: 'email',
        placeholder: '请输入电子邮箱',
        required: false,
        verify: {},
        disabled: disabled,
    },
    {
        type: 'select',
        textName: '学习类别',
        valueName: 'learningType',
        placeholder: '',
        required: true,
        verify: {},
        initVal: [0],
        select: [
            { label: '初次申领', value: 0 },
            { label: '增驾', value: 1 },
        ],
        disabled: disabled,
    },
    {
        type: 'select',
        textName: '所学车型',
        valueName: 'carId',
        placeholder: '',
        required: true,
        ok: selectOkCarId,
        verify: {},
        select: changeTemplateModelList(template),
        disabled: disabled,
    },
    ]
}

export const fromTableContactRegion = (regionBySource, forbid, disabled) => {
    return [{
        type: 'select',
        textName: '联系住所行政区',
        valueName: 'contactRegion',
        placeholder: '',
        required: true,
        verify: {},
        ok: selectOkContactRegion,
        cols: 3,
        select: regionBySource,
        disabled: disabled,
        textClass: 'text-name-class'
    },
    {
        type: 'textarea',
        textName: '联系住所详细地址',
        valueName: 'contactAddress',
        placeholder: '请输入联系住所详细地址',
        required: true,
        verify: requiredTrue,
        disabled: disabled,
        textareaClass: 'text-textarea-class'
    },
    {
        type: 'input',
        textName: '邮政编码',
        valueName: 'zipCode',
        placeholder: '选择住所行政区划后自动带出',
        required: true,
        verify: requiredTrue,
        disabled: disabled,
    },
    {
        type: 'input',
        textName: '暂住证编号',
        valueName: 'residenceNumber',
        placeholder: forbid ? '不需要填写' : '请填写暂住证号',
        required: forbid ? false : true,
        verify: requiredTrue,
        disabled: disabled,
        forbid: forbid,
    },
    ]
}