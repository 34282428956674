import React, { useState } from 'react'
import { Modal } from 'antd-mobile'

const RequireText: React.FC<Picture.requirementProps> = (props: Picture.requirementProps): JSX.Element => {
  const [modelShow, setModelShow] = useState(false)

  const renderList = (txtList: string[]) => txtList.map((txt: string, index: number) => {
    return <span className='txt-item' key={index}>{txt}</span>
  })

  const { texts, exImg, title } = props

  const lookExampleClick = () => {
    setModelShow(true)
  }

  const modalClose = () => {
    setModelShow(false)
  }

  const requirementComponent = (txtList: string[]) => {//<img className='sample-img' src={circleSrc} alt="" />
    const renderExampleTxt = () => exImg ? <div className='sample-text' onClick={() => lookExampleClick()}>查看示例  </div> : null
    return (
      <div className='requirements'>
        <div className='flex require-content'>
          <div className='txt-main'>
            <div className=''>要求：</div>
          </div>
          <div className='txt-content'>
            {renderList(txtList)}
            {renderExampleTxt()}
          </div>
        </div>

      </div>
    )
  }

  return (
    <>
      {requirementComponent(texts)}

      <Modal
        className="modal-box"
        visible={modelShow}
        transparent
        maskClosable={false}
        onClose={modalClose}
        title={title}
        footer={[{ text: '我知道了', onPress: () => { modalClose() } }]}
      >
        <img className="exImg" src={exImg} alt="" />
      </Modal>
    </>
  )
}

export default RequireText
